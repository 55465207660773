import axios from "axios";
import { store } from "../store/store"
import { logoutUser } from "./Authentication/userAuthentication";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

Api.interceptors.request.use((config) => {
  const { token } = store.getState().authPersistReducer; // Acesse o token armazenado no Redux
  if (token && !config.headers.Authorization) {
    config.headers.Authorization = "Bearer " + token;
  }

  return config;
});

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response?.status) {
      logoutUser();
      // return window.location.assign('/login')
    } else {
      return Promise.reject(error);
    }
  }
);

export default Api;