import "./HostApprovalDetail.css";
import * as React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QuestsAcordeon from "../../components/QuestsDashboard/QuestsAcordeon/index.jsx";
import Drawer from "@mui/material/Drawer";
import LineManagerTabs from "../LineManagerTabs/index.jsx";
import Avatar from "@mui/material/Avatar";

export default function HostApprovalDetail({
  questInfo,
  questee,
  callAdd,
  type
}) {
  const [state, setState] = React.useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  return (
    <div>
      <React.Fragment>
        <button className="approve-button-modal" onClick={toggleDrawer()}>
          view details <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
        </button>
        <Drawer
          className="drawer-quests"
          variant="temporary"
          anchor="right"
          open={state}
          onClose={toggleDrawer()}
        >
          <div
            className="drawer-body"
            style={{ width: "60vw", maxWidth: "600px", marginTop: "0px" }}
          >
            <Avatar
              sx={{ width: 60, height: 60, marginRight: "8px" }}
              alt=""
              src={`data:image/jpeg;base64,${questee.questeePicture}`}
            />
            <h1>{questee.questeeName}</h1>
            <LineManagerTabs
              title1="Questee Information"
              title2="Opportunity Information"
              element1={
                <>
                  <div className="quest-objectives-modal">
                    <div className="quest-objectives-modal">
                      <span className="modal-label">Quest Objectives</span>

                      {questee.questions
                        .filter(
                          (q) => q.question.indexOf("objective_type") > -1
                        )
                        .map((q, index) => {
                          let item = q.question.split("_")[2];
                          return (
                            <QuestsAcordeon
                              title={q.answer}
                              key={index}
                              description={
                                questee?.questions?.find(
                                  (q) =>
                                    q.question.indexOf("objective_" + item) > -1
                                ).answer
                              }
                            />
                          );
                        })}
                    </div>
                  </div>

                  <div className="quest-values-modal">
                    <span className="modal-label">Value to Stakeholders</span>

                    {questee?.questions
                      ?.filter((q) => q.question.indexOf("value") > -1)
                      .map((q, index) => {
                        return (
                          <div key={index}>
                            <span className="input-title">{q.label}</span>
                            <div className="text-div-dash">
                              <span>{q.answer}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              }
              element2={
                <>
                  <div className="quest-objectives-modal">
                    <div className="quest-objectives-modal">
                      <span className="modal-label">Quest Objectives</span>

                      {questInfo?.opportunityInformation?.filter(
                          (q) => q.question.indexOf("objective_type") > -1
                        )
                        .map((q, index) => {
                          let item = q.question.split("_")[2];
                          return (
                            <QuestsAcordeon
                              title={q.answer}
                              key={index}
                              description={
                                questInfo?.opportunityInformation?.find(
                                  (q) =>
                                    q.question.indexOf("objective_" + item) > -1
                                ).answer
                              }
                            />
                          );
                        })}
                    </div>
                  </div>

                  <div className="quest-values-modal">
                    <span className="modal-label">Value to Stakeholders</span>

                    {questInfo?.opportunityInformation?.filter((q) => q.question.indexOf("value") > -1)
                      .map((q, index) => {
                        return (
                          <div key={index}>
                            <span className="input-title">{q.label}</span>
                            <div className="text-div-dash">
                              <span>{q.answer}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              }
            />
            <div className="modal-buttons-div">
             {type!=1&&<button
                style={{
                  width: "fit-content",
                  backgroundColor: "rgba(228, 97, 15, 1)",
                  padding: "6px, 12px, 6px, 12px",
                }}
                // disabled={questeeData.status !== 'pending'}
                onClick={callAdd}
                className="select-button"
              >
                Select Questee
              </button>}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
