import { createSlice } from "@reduxjs/toolkit";

const loadingSlicer = createSlice({
  name: "loadingSlicer",
  initialState: {
    loadingState: { display: "none" },
  },
  reducers: {
    toggleLoadingHandler: (state, action) => {
      state.loadingState = { display: "block" };
    },
    untoggleLoadingHandler: (state, action) => {
      state.loadingState = { display: "none" };
    },
  },
});

export const toggleLoadingHandler = loadingSlicer.actions.toggleLoadingHandler;
export const untoggleLoadingHandler =
  loadingSlicer.actions.untoggleLoadingHandler;

export default loadingSlicer.reducer;
