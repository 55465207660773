import "./PoliciesModal.css";
import * as React from "react";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  fillDataDynamically,
  fillQuesteeDataHandler,
} from "../../store/redux/questeeSlice";

import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import AccordionComponent from "../AccordionComponent";
import Checkbox from "@mui/material/Checkbox";
import Api from "../../services/Api";
import Loader from "../Loader";
import PrivacyText from "../Texts/PrivacyText";

export default function PoliciesModal({ element, func }) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  
  const { hasFetched } = useSelector((state) => state.userReducer);
  const userRole = useSelector((state) => state.authPersistReducer.userRole);
  const userData = useSelector((state) => state.authPersistReducer.userData);

  const fillQuesteeData = () => {
    if (userData?.Quests?.length) {
      dispatch(
        fillQuesteeDataHandler({
          questId: userData.Quests[0].questId,
          type: userData.Quests[0].type,
          notFlexibleDate: userData.Quests[0].reasonForDate,
          country: userData.Quests[0].country,
          office: userData.Quests[0].office,
          hostEmail: userData.host?.User?.email,
          month: userData.Quests[0].month,
          year: userData.Quests[0].year,
          flexibleDate: userData.Quests[0].dateFlexible,
          holidaysConflicts: userData.Quests[0].holidaysConflict,
          holidayHasConflict: userData.Quests[0].reasonForHoliday,
          objective1: userData.questions[0].answer,
          objective1Description: userData.questions[1].answer,
          objective2: userData.questions[2].answer,
          objective2Description: userData.questions[3].answer,
          objective3: userData.questions[4].answer,
          objective3Description: userData.questions[5].answer,
          value1: userData.questions[6].answer,
          value2: userData.questions[7].answer,
          value3: userData.questions[8].answer,
          status: userData.Quests[0].QuesteeInfos[0].status,
          questStep: userData.Quests[0].QuesteeInfos[0].questStep,
        })
      );
    }
  };

  useEffect(() => {}, [hasFetched]);
  const navigate = useNavigate();
  const policyText =
    "Lovinklaan Foundation and Arcadis appreciate your time and investment in this program to further develop yourself, your colleagues, and our business. We are investing in you and are committed to you as a future leader. We want your commitment to the business to be as high as our commitment to you. We anticipate that individuals awarded with a Quest experience will continue to be an Arcadian for years to come. Our expectation is that Questees will remain within Arcadis for at least one year after completing the Quest experience.";

  if (userData && userData.Quests && userData.Quests.length > 0) {
    dispatch(
      fillDataDynamically({
        value: userData.Quests[0].questId,
        target: "questId",
      })
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = async () => {
    await Api.put(`/user/agreementsUpdate/${userData.userId}`);
    func();
    navigate("/onboarding");
  };

  const handleCheckBox = () => {
    if (check === false) {
      setCheck(true);
      setIsDisabled(false);
    }
    if (check === true) {
      setCheck(false);
      setIsDisabled(true);
    }
  };
  const completeApplication = () => {
    dispatch(
      fillDataDynamically({
        value:
          userData.Quests.length > 0 && userData.Quests[0].type == "1" ? 0 : 1,
        target: "formIndex",
      })
    );

    func();
    fillQuesteeData();
    navigate("/onboarding");
  };

  const text =
    "You are about to embark on a once-in-a-career experience, meet amazing people, and begin forming lasting connections. By completing a Quest, you are joining an incredible group of Quest Alumni who are making a difference all around the world.";

  return (
    <div>
      {userData ? (
        <>
          <div onClick={handleClickOpen}>{element}</div>
          <Dialog open={open} onClose={handleClose}>
            <div className="privacy-modal-holder">
              {
                //verifica se a pessoa pode se candidatar
                !userData.isEligible ? (
                  <>
                    <div className="privacy-modal-header">
                      <h2>
                        I apologize, but you are not eligible to participate as
                        a Questee. Either you have previously been a Questee, or
                        you have not been with Arcadis for a full year yet.
                      </h2>
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </>
                ) : // verifica se a pessoa pode se a pessoa ceitou os termos
                userData.questeeConditionsAgreed == false ? (
                  <>
                    <div className="modal-header">
                      <h2> Excellent choice, {userData.name}!</h2>
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <p style={{ textAlign: "justify", padding: "10px" }}>
                      {text}
                    </p>
                    <div>
                      <AccordionComponent
                        title="Commitment Pledge"
                        text={policyText}
                      />
                      <AccordionComponent
                        title="Privacy Notice"
                        text={<PrivacyText />}
                      />
                    </div>
                    <span className="input-label" style={{ marginTop: "10px" }}>
                      Consent*
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Checkbox
                        {...label}
                        checked={check}
                        onClick={handleCheckBox}
                      />
                      <span>
                        I agree to the privacy notice and the commitment pledge.
                      </span>
                    </div>
                    <div
                      className={
                        isDisabled
                          ? "large-button-div-not-active"
                          : "large-button-div"
                      }
                    >
                      <button
                        onClick={() => handleClick(userData.userId)}
                        disabled={isDisabled}
                      >
                        {" "}
                        {userRole === 2 ? (
                          <>I want to be a Host</>
                        ) : (
                          <>I want to be a Questee</>
                        )}
                      </button>
                    </div>
                  </>
                ) : // verifica se a aplicação está em progresso
                userData.questeeStatus == "P" ? (
                  <>
                    <div className="modal-header">
                      <h2> It is good to have you back, {userData.name}!</h2>
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={"large-button-div"}>
                      <button onClick={completeApplication}>
                        Continue the application
                      </button>
                    </div>
                  </>
                ) : // verifica se a aplicação está em  revisão
                userData.questeeStatus == "R" ? (
                  <>
                    <div className="privacy-modal-header">
                      <h2>
                        Your application is currently under review by the line
                        manager!
                      </h2>
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={"large-button-div"}>
                      <button
                        onClick={() => {
                          fillQuesteeData();
                          navigate("/dashboard");
                        }}
                      >
                        Go to dashboard
                      </button>
                    </div>
                  </>
                ) : (
                  //direciona a pessoa pra aplicação
                  <>
                    <div className="modal-header">
                      <h2> It is good to have you back, {userData.name}!</h2>
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={"large-button-div"}>
                      <button onClick={completeApplication}>
                        Continue the application
                      </button>
                    </div>
                  </>
                )
              }
            </div>
          </Dialog>
        </>
      ) : (
        <div style={{ marginLeft: "0px" }}>
          <Loader type={"spinningBubbles"} color={"#ffffff"} />
        </div>
      )}
    </div>
  );
}
