import "./ManagerDashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fillDataDynamically } from "../../store/redux/questeeSlice";
import Api from "../../services/Api";
import ProfileComponent from "../../components/Dashboard/ProfileComponent";
import QuesteeTable from "../../components/QuesteeTable";
import Header from "../../components/Header";
import NoQuestsToReviewMessage from "../../components/NoQuestsToReviewMessage";
import LineManagerTabs from "../../components/LineManagerTabs";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";

export default function LineManagerDashboard() {
  const [managerQuests, setManagerQuests] = useState([]);
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const pendingApplications = [];
  const completedQuests = [];

  const { updateQuestsForManager } = useSelector(
    (state) => state.questeePersistReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getManagerQuests();
  }, [setManagerQuests, userData]);

  async function getManagerQuests() {
    dispatch(toggleLoadingHandler());

    const response = await Api.get(`/approval/lineManager/${userData.userId}`);
    dispatch(untoggleLoadingHandler());

    setManagerQuests(response.data);
  }

  managerQuests.forEach((quest) => {
    const { status } = quest;
    if (status === "pending") {
      pendingApplications.push(quest);
    } else if (status === "approved" || status === "rejected") {
      completedQuests.push(quest);
    }
  });

  if (updateQuestsForManager) {
    getManagerQuests();
    dispatch(
      fillDataDynamically({
        value: false,
        target: "updateQuestsForManager",
      })
    );
  }

  return (
    <div className="main-container">
      <Header />
      <div className="form-header">
        <h1>Hi, Line Manager!</h1>
        <p>The Questees below are waiting for your approval!</p>
      </div>

      <div className="manager-dashboard-page">
        <div className="manager-data">
          <LineManagerTabs
            title1="Pending Applications"
            title2="Completed"
            element1={
              <>
                {pendingApplications.length > 0 ? (
                  <>
                    <QuesteeTable type={2} quests={pendingApplications} />
                  </>
                ) : (
                  <NoQuestsToReviewMessage
                    title="You have no Quests to review"
                    style={{ width: "460px" }}
                  />
                )}
              </>
            }
            element2={
              <>
                {completedQuests.length > 0 ? (
                  <>
                    <QuesteeTable type={2} quests={completedQuests} />
                  </>
                ) : (
                  <NoQuestsToReviewMessage
                    title="You have no Quests to review"
                    style={{ width: "460px" }}
                  />
                )}
              </>
            }
          />
        </div>
        <div className="perfil-div-line">
          <ProfileComponent name={userData.name} lastName={userData.lastName} />
        </div>
      </div>
    </div>
  );
}
