import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    auth: "",
    token: "",
    msToken: "",
    name: "",
    userRole: [],
    pathLocation: "",
    isApplication: false,
    userData:
      {
        userId: "",
        name: "",
        lastName: "",
        email: "",
        newUser: false,
        onboardingFinished: false,
        questeeConditionsAgreed: false,
        applicationCompleted: false,
        isEligible: true,
        roles: [],
      }
  },
  reducers: {
    signIn: (state, action) => {
      state.userRole = action.payload.userRole;
      state.name = action.payload.name;
      state.msToken = action.payload.msToken;
      state.token = action.payload.token;
      state.isEligible = action.payload.isEligible;
      state.questeeStatus = action.payload.questeeStatus;
      state.questeeConditionsAgreed = action.payload.questeeConditionsAgreed;
    },
    storeUserData: (state, action) => {
      if (action.payload.data) {
        // if (state.userData.length > 0) {
        //   state.userData.splice(0, 1, action.payload.data);
        // } else {
          state.userData = Array.isArray(action.payload.data) && action.payload.data.length>0? action.payload.data[0] : action.payload.data;
        // }
      } else {
        if (state.userData.length > 0) {
          state.userData.splice(0, 1, action.payload);
        } else {
          state.userData = action.payload;
        }
      }
    },
    isApplicationHandler: (state, action) => {
      state.isApplication = action.payload.status;
    },
    msTokenHandler: (state, action) => {
      state.msToken = action.payload.msToken;
    },
    setAuthHandler: (state, action) => {
      state.auth = action.payload.auth;
    },
    routesAuthHandler: (state, action) => {
      state.pathLocation = action.payload.pathLocation;
    },
    newQuestFormHandler: (state, action) => {
      state.userData = action.payload;
    },
  },
});
export const signIn = authSlice.actions.signIn;
export const storeUserData = authSlice.actions.storeUserData;
export const isApplicationHandler = authSlice.actions.isApplicationHandler;
export const msTokenHandler = authSlice.actions.msTokenHandler;
export const setAuthHandler = authSlice.actions.setAuthHandler;
export const routesAuthHandler = authSlice.actions.routesAuthHandler;
export const newQuestFormHandler = authSlice.actions.newQuestFormHandler;

export default authSlice.reducer;
