import React, { useEffect, useRef, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from "react-redux";


export default function PeoplePicker({defaultValue, onChangeFunction}) {


const [options, setOptions] = useState([]);
const previousController = useRef();

const msToken = useSelector((state) => state.authPersistReducer.msToken);

const getData = (searchTerm) => {
  if (previousController.current) {
    previousController.current.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  previousController.current = controller;

  fetch(`https://graph.microsoft.com/v1.0/users?$search="displayName:${searchTerm}" OR "mail:${searchTerm}"`, {
    signal,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${msToken}`,
      ConsistencyLevel: "eventual"
    }
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      const updatedOptions = myJson.value.map((p) => {
        return { title: p.mail };
      });
      if (updatedOptions.length === 0) {
        updatedOptions.push({ title: searchTerm });
      }
      setOptions(updatedOptions);
    })

};

const onInputChange = (event, value, reason) => {
  if (value && value.length > 1) {
    getData(value);
  }
};

useEffect(() => {console.log(options)}, [options]);

  return (
    
    <Autocomplete
    id="combo-box-demo"
    options={options}
    onInputChange={onInputChange}
    getOptionLabel={(option) => option.title}
    defaultValue={{ title: defaultValue}}
    onChange={(event, value) =>  {onChangeFunction(value.title)}}
    freeSolo={true}
    style={{ width: "90%", marginLeft: "5%" }}
    renderInput={(params) => (
      <TextField {...params} variant="outlined" placeholder="name.surname@arcadis.com"/>
    )}
  />
  );
}