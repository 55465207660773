import "./loadingIcon.css";
import { useSelector } from "react-redux";

import React from "react";
import LoadingICON from "../../assets/images/icons/waiting.svg";

export default function LoadingIcon() {
  const { loadingState } = useSelector((state) => state.loadReducer);

  return (
    <div
      className="loading-holder-item"
      style={{ display: `${loadingState.display}` }}
    >
      <div className="loading-holder-image">
        <img src={LoadingICON} alt="" />
      </div>
    </div>
  );
}
