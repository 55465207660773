import Header from "../Header";
import noQuest2 from "../../assets/images/noQuest2.svg";
import "./PageNotAllowedMessage.css";

export default function PageNotAllowedMessage() {
  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: "#f0f4f9",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          justifyContent: "center",
          overflow: "scroll",
          paddingTop: "10vh",
        }}
      >
        <div className="unauthorized-card">
          <div>
            <img alt="unauthorized" src={noQuest2}></img>
          </div>
          <h1>Sorry, you're not allowed on this page</h1>
        </div>
      </div>
    </>
  );
}
