import "./HostApprovalDrawer.css";
import "../LineManagerDrawer/LineManagerDrawer.css";
import * as React from "react";
import { useEffect } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fillDataDynamically } from "../../store/redux/questeeSlice.js";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Drawer from "@mui/material/Drawer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Api from "../../services/Api.jsx";
import SelectQuesteeBox from "../SelectQuesteeBox/index.jsx";
import NotePopup from "../NotePopup/index.jsx";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import checkDuplicatedCountries from "../../utils/checkDuplicatedCountries.js";
import QuestTypeTag from "../QuestTypeTag/indx.jsx";
import DefaultButton from "../DefaultButton/index.jsx";
import { useCallback } from "react";

export default function HostApprovalDrawer({
  questInfo,
  isLovinklaan,
  questees,
  type,
  updateData,
  status
}) {

  const dispatch = useDispatch();

  const [state, setState] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [notSelected, setNotSelected] = React.useState([]);
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const [sameCountry, setSameCountry] = React.useState(false);
  const [duplicateCountry, setDuplicateCountry] = React.useState(false);
  const { isReady } = useSelector((state) => state.questeePersistReducer);
  const [observation, setObservation] = React.useState("");
  const [isRejected, setIsRejected] = React.useState(false);

  const reduxFillDataDynamicallyHandler = (value, target) => {
    dispatch(
      fillDataDynamically({
        value: value,
        target: target,
      })
    );
  };

  const checkCountries = (toFilter) => {
    /**
     * Function to check if there is a questee with mnore than one country
     * @param {any} toFilter parameter with selected questees
     */

    let counts = {};
    let countries = toFilter.map((q) => q.country);
    countries.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });

    let same = false;

    Object.values(counts).forEach((val, key) => {
      if (val >= 3) {
        same = true;
      }
    });
    setDuplicateCountry(same);
  };

  const separateQuestees = useCallback(() => {
    /**
     * Add questees initial state of selected or not selected
     */

    let initialSelected = [];
    let initialNotSelected = [];

    questees.forEach((questee) => {
      const { isSelected } = questee;
      if (isSelected === true) {
        initialSelected.push(questee);
      } else if (isSelected === false) {
        initialNotSelected.push(questee);
      }
    });

    setSelected(initialSelected);
    setNotSelected(initialNotSelected);

    checkCountries(initialSelected);
  }, []);

  useEffect(() => {
    separateQuestees();

    checkDuplicatedCountries(selected, reduxFillDataDynamicallyHandler);
  }, []);

  const toggleDrawer = () => (event) => {
    /**
     * Toggle the Drawer from questees
     */
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  const removeQuestee = (id) => {
    /**
     * On user press to remove, update the arrays of selected and not selected
     * @param {int} id id of removed questee
     */

    const filteringSelected = selected.filter(
      (questee) => questee.questeeId !== id
    );

    setSelected(filteringSelected);

    setNotSelected([...notSelected, questees.find((q) => q.questeeId == id)]);
    if (!isLovinklaan) {
      checkCountries(filteringSelected);
    }
  };

  const addQuestee = (id) => {
    /**
     * On user press to add questee, update the arrays of selected and not selected,
     * also checking if questee is from same country as quest or
     * has two questees from the same country
     * @param {int} id id of added questee
     */
    let filteringNotSelected = notSelected.filter(
      (questee) => questee.questeeId.toString() !== id.toString()
    );

    setSelected([...selected, questees.find((q) => q.questeeId == id)]);

    setNotSelected(filteringNotSelected);

    if (!isLovinklaan) {
      checkCountries([...selected, questees.find((q) => q.questeeId == id)]);
    }

    setSameCountry(
      questees.find((q) => q.questeeId == id)
        ? questees.find((q) => q.questeeId == id).country == questInfo.country
        : false
    );
  };

  const updateQuest = async () => {
    /**
     * Send request when user finished to choose questees and update quest
     * Send the data aof approved and rejected questees
     */
    dispatch(toggleLoadingHandler());
    setState(false);
    Api.post("/approval/approveQuestees", {
      stepId: isLovinklaan ? 5 : 3,
      questId: questInfo.questId,
      userId: userData.userId,
      selected: selected.map((questee) => {
        return {
          questeeId: questee.questeeId,
          questeeName: questee.questeeName,
          country: questee.country,
          observation: questee.observation,
        };
      }),
      notSelected: notSelected.map((questee) => {
        return {
          questeeId: questee.questeeId,
          questeeName: questee.questeeName,
          country: questee.country,
          observation: questee.observation,
        };
      }),
    })
      .then(() => {
        dispatch(untoggleLoadingHandler());
        window.location.reload(true);
        dispatch(
          fillDataDynamically({
            value: !isReady,
            target: "isReady",
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveOrRejectQuest = async (status) => {
    /**
     * Approve or reject quest
     * Use if there is only one questee
     * Updates the quest
     * @param {string} status status of "approved" or "rejected"
     */

    dispatch(toggleLoadingHandler());

    setState(false);
    Api.post(`/approval/updateStep/${questInfo.questId}`, {
      status: status === "approved" ? "approved" : "rejected",
      observation: observation,
      approvalId: userData.userId,
      currentStep: isLovinklaan ? 5 : 3,
      questeeId: questees[0].questeeId,
      isQuestee: true,
    })
      .then(() => {
        updateData(status, questInfo.questId);
        dispatch(untoggleLoadingHandler());
        window.location.reload(true);
        dispatch(
          fillDataDynamically({
            value: !isReady,
            target: "isReady",
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateMessage = (selected, maxToSelect) => {
    /**
     * Check if there is still spots for the user to select questees and crete message
     * @param {int} selected qnty of selected people
     * @param {int} selected qnty of max selection
     */
    if (selected < maxToSelect) {
      return (
        <>
          You still have slots available for this Quest.
          Please do not submit your selections until you have made a 
          final decision regarding all of the applicants. 
          Once you click, "submit" the system will automatically 
          send a rejected email to those you did not select. 
          Please return when you have made a final decision.
          <div>
            <PeopleAltOutlinedIcon />
            {selected}/{questInfo.maxQty}
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <React.Fragment>
        <button className="approve-button-modal" onClick={toggleDrawer()}>
          View all applications
          <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
        </button>
        <Drawer
          className="drawer-quests"
          variant="temporary"
          anchor="right"
          open={state}
          onClose={toggleDrawer()}
        >
          <div className="drawer-body" style={{ width: "75vw" }}>
            <h1>Application details</h1>

            <div className="first-tags">
              <div className="tag">
                <h1>Type</h1>
                <QuestTypeTag maxQt={questInfo.maxQty} />
              </div>
              <div className="tag">
                <h1>Host Location</h1>
                <h2>
                  <LocationOnIcon style={{ fontSize: "16px" }} />
                  {questInfo.country}
                </h2>
              </div>
              <div className="tag">
                <h1>Line Manager</h1>
                <h2>
                  <img
                    style={{ width: 20, height: 20, borderRadius: "10px" }}
                    src={`data:image/jpeg;base64,${questInfo.lineManagerPicture}`}
                  />
                  {questInfo.lineManager}
                </h2>
              </div>
            </div>
            <div className="host-selected"></div>
            <div className="host-all-applications">
              {selected.length === questInfo.maxQty && type !== "Single Quest" && (
                <Alert
                  severity="warning"
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <span className="alert1" style={{ fontSize: "14px" }}>
                    {selected.length}/{questInfo.maxQty} You have already
                    reached the number of applicants for this opportunity
                  </span>
                </Alert>
              )}
              

              {sameCountry && (
                <Alert
                  severity="warning"
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <span className="alert1" style={{ fontSize: "14px" }}>
                    You can't select questees for the same country as yours!
                  </span>
                </Alert>
              )}
              {duplicateCountry && (
                <Alert
                  severity="error"
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <span className="alert1" style={{ fontSize: "14px" }}>
                    You can submit only two questees from the same country in
                    this opportunity.
                  </span>
                </Alert>
              )}
              {type !== "Single Quest" && (
                <h1>
                  Selected {selected.length}/{questInfo.maxQty}
                </h1>
              )}
              {selected.map((questee, index) => (
                  <SelectQuesteeBox
                  key={index}
                  questee={questee}
                  addFunction={(data) => addQuestee(data)}
                  removeFunction={removeQuestee}
                  arrayLength={questees.length}
                  questInfo={questInfo}
                  isSelected={true}
                  type={type == "Single Quest" || status !== "pending"?1:2}
                />
              ))}
            </div>

            <div className="host-all-applications">
            {notSelected.length>0&& <h1>All Applications</h1>}
              {notSelected.map((questee, index) => {
                return (
                  <SelectQuesteeBox
                    key={index}
                    questee={questee}
                    addFunction={(data) => addQuestee(data)}
                    removeFunction={removeQuestee}
                    arrayLength={questees.length}
                    questInfo={questInfo}
                    isSelected={false}
                    type={type == "Single Quest" || status !== "pending"?1:2}
                  />
                );
              })}
            </div>
            {questInfo.status !== "approved" &&
              type !== "Single Quest" &&
              !duplicateCountry &&
              !sameCountry && (
                <div className="approval-bttns">
                  <NotePopup
                    title={<>Select Questees</>}
                    classOption="able-bttn"
                    infoMessage={updateMessage(
                      selected.length,
                      questInfo.maxQty
                    )}
                    block={!isLovinklaan && selected.length > questInfo.maxQty}
                    option2={
                      <>
                        Submit Questees
                        <ArrowForwardIosIcon />
                      </>
                    }
                    titleMessage="Are you sure you want to continue?"
                    action2={() => updateQuest(true)}
                  />
                </div>
              )}

            {type === "Single Quest" && status === "pending" && (
              <div className="btn-container">
                <DefaultButton
                  title="Approve"
                  onClick={() => saveOrRejectQuest("approved")}
                />
                <DefaultButton
                  title="Reject"
                  onClick={() => setIsRejected(true)}
                />
              {isRejected && (
                <>
                <p className="line-manager-comment-title">
                Describe how the Questee can improve their application for future cycles. 
                  </p>
                <textarea
                  onChange={(e) => setObservation(e.target.value)}
                  className="line-manager-comment-input"
                />
                <button
                  onClick={() => saveOrRejectQuest("rejected")}
                  className="submit-button"
                >
                  SUBMIT
                </button>
                </>
              )}
              </div>
            )}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
