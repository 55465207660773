import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './DashboardAccordion.css'

export default function DashboardAccordion({title, info}) {
  return (
      <Accordion>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className='accordion'
        >
          <ArrowForwardIosIcon style={{color: '#E4610F'}}/>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {info}
          </Typography>
        </AccordionDetails>
      </Accordion>
  );
}
