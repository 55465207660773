import Login from "../../assets/images/Login.png";
import ssoLogo from "../../assets/images/icons/ssoLogo.png";

import SignInButton from "../../components/SignInButton";
import "./LoginPage.css";

export default function LoginPage() {
 
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        backgroundColor: "blue",
      }}
    >
      <img alt="quest logo" src={Login} height="100%"></img>
      <div
        style={{
          backgroundColor: "#f7fafc",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="login-title">Welcome Back</h1>
        <SignInButton
          style={{ backgroundColor: "#140001", width: "20px" }}
          title={
            <>
              <img alt="quest logo" width="24px" src={ssoLogo}></img> Single
              Sign on
            </>
          }
        />
      </div>
    </div>
  );
}
