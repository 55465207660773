import { createSlice } from "@reduxjs/toolkit";

const hostSlice = createSlice({
  name: "hostSlice",
  initialState: {
    questeeQty: "",
    year: "",
    month: "",
    budget: "",
    hostId: "",
    objective1: "",
    objective2: "",
    objective3: "",
    objectiveDesc1: "",
    objectiveDesc2: "",
    objectiveDesc3: "",
    projectDetail: "",
    questMission: "",
    questKnowledge: "",
    questeeDevelopment: "",
  },
  reducers: {
    fillHostDataDynamically: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
  },
});

export const fillHostDataDynamically =
  hostSlice.actions.fillHostDataDynamically;

export default hostSlice.reducer;
