import { createSlice } from '@reduxjs/toolkit';

const questSlice = createSlice({
    name: 'questSlice',
    initialState: {
        traditionalQuestOpen: false,
        gbaQuestOpen: false,
        hostQuestOpen: false,
        traditionalQuestId:0,
        gbaQuestId:0,
        hostQuestId:0
    },
    reducers: {
        fillQuestDataDinamically: (state, action) => {
            state.traditionalQuestOpen= action.payload.traditionalQuestOpen;
            state.gbaQuestOpen= action.payload.gbaQuestOpen;
            state.hostQuestOpen= action.payload.hostQuestOpen;
            state.traditionalQuestId= action.payload.traditionalQuestId;
            state.gbaQuestId= action.payload.gbaQuestId;
            state.hostQuestId= action.payload.hostQuestId;
        },
    },
});

export const { fillQuestDataDinamically } = questSlice.actions;

export default questSlice.reducer;