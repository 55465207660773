import "./QuestInformationModal.css";
import * as React from "react";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { reduxApplyToQuest } from "../../store/redux/questeeSlice.js";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QuestsAcordeon from "../QuestsDashboard/QuestsAcordeon/index.jsx";
import Drawer from "@mui/material/Drawer";
import NotePopup from "../NotePopup/index.jsx";
import PoliciesModal from "../PoliciesModal/index.jsx";

export default function QuestInformationModal({ questInfo }) {
  const [state, setState] = React.useState(false);

  const dispatch = useDispatch();

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  async function applyToQuest(questId) {
    dispatch(
      reduxApplyToQuest({
        applyingToHostQuest: true,
        country: questInfo.country,
        formIndex:questInfo.type==1?0:1,
        hostEmail: questInfo.hostEmail,
        office: questInfo.office,
        questApplication: true,
        questIdToApply: questId,
        type: questInfo.type,
      })
    );
  }

  return (
    <div>
      <React.Fragment>
        <button className="see-more-button" onClick={toggleDrawer()}>
          Submit an application{" "}
          <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
        </button>
        <Drawer
          className="drawer-quests"
          variant="temporary"
          anchor="right"
          open={state}
          onClose={toggleDrawer()}
        >
          <div className="drawer-body">
            <h1>Quest Information</h1>

            <div className="first-tags">
              <div className="tag">
                <h1>Quest Location</h1>
                <div>
                  <h2>
                    <PlaceOutlinedIcon className="loc-icon" />{" "}
                    {questInfo.office}
                  </h2>
                </div>
              </div>
              <div className="tag">
                <h1>Travel month/year</h1>
                <div>
                  <h2>
                    <CalendarMonthOutlinedIcon />
                    {questInfo.month}/{questInfo.year}
                  </h2>
                </div>
              </div>
              <div className="tag">
                <h1>Host</h1>
                <div>
                  <h2>{questInfo.hostEmail}</h2>
                </div>
              </div>
            </div>

            {/* <div className="second-tags">
              <div className="tag">
                <h1>GBA Officer</h1>
                <div>
                  <h2>{questInfo.gbaOfficer}</h2>
                </div>
              </div>
              <div className="tag">
                <h1>BA Officer</h1>
                <div>
                  <h2>{questInfo.baDirector}</h2>
                </div>
              </div>
              <div className="tag"></div>
            </div> */}

            <div className="quest-objectives-modal">
              <span className="modal-label">Quest Objectives</span>

              {questInfo.questions
                .filter((q) => q.question.indexOf("objective_type") > -1)
                .map((q, index) => {
                  let item = q.question.split("_")[2];
                  return (
                    <QuestsAcordeon
                      title={q.label}
                      key={index}
                      description={
                        questInfo?.questions?.find(
                          (q) => q.question.indexOf("objective_" + item) > -1
                        ).answer
                      }
                    />
                  );
                })}
            </div>

            <div className="quest-values-modal">
              <span className="modal-label">Value to Stakeholders</span>{" "}
              <br></br>
              {questInfo.questions
                .filter((q) => q.question.indexOf("value") > -1)
                .map((q, index) => {
                  return (
                    <div key={index}>
                      <span
                        className="modal-label"
                        style={{ fontSize: "13px" }}
                      >
                        {q.label}
                      </span>
                      <div className="quest-info-text">
                        <span>{q.answer}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Alert
              severity="warning"
              style={{ marginTop: "24px", marginBottom: "24px" }}
            >
              <span className="alert1">
                You can submit only one registration in GBA predefined Quests{" "}
              </span>
              <span className="span2">
                Read all details and main objectives carefully to make sure that
                it fits your profile.
              </span>
            </Alert>

            <NotePopup
              titleMessage="You can only submit one application! Are you sure you want to continue?"
              option2={
                <PoliciesModal
                  func={() => {
                    applyToQuest(questInfo.questId);
                  }}
                  element={<>Apply to this opportunity</>}
                />
              }
              classOption="send-button"
              title="Apply as a Questee"
            />
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
