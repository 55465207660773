import "./LovinklaanDashboard.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Api from "../../services/Api";
import ProfileComponent from "../../components/Dashboard/ProfileComponent";
import QuesteeTable from "../../components/QuesteeTable";
import Header from "../../components/Header";
import NoQuestsToReviewMessage from "../../components/NoQuestsToReviewMessage";

export default function LovinklaanDashboard() {
  const [managerQuests, setManagerQuests] = useState([]);
  const userData = useSelector((state) => state.authPersistReducer.userData);
  useEffect(() => {
    getManagerQuests();

    async function getManagerQuests() {
      const response = await Api.get(`/approval/lovinklan`);

      setManagerQuests(response.data);
    }
  }, [setManagerQuests, userData]);

  return (
    <div className="main-container">
      <Header />
      <div className="form-header">
        <h1>Hi, Lovinklaan!</h1>
        <p>The Questees below are waiting for your approval!</p>
      </div>
      <div className="dashboard-page">
        {managerQuests.length ? (
          <>
            <input className="search-bar" placeholder="Search"></input>
            <QuesteeTable type={5} quests={managerQuests} />
          </>
        ) : (
          <NoQuestsToReviewMessage title="You have no Quests to review" />
        )}
        <ProfileComponent name={userData.name} lastName={userData.lastName} />
      </div>
    </div>
  );
}
