//Componente com a caixa de seleção de questee, com botão de checar e ver detalhes da aplicação do questee

import Avatar from "@mui/material/Avatar";
import "./SelectQuesteeBox.css";
import { useState } from "react";
import { Checkbox, FormControlLabel, TextareaAutosize, TextField } from "@mui/material";
import HostApprovalDetail from "../HostApprovalDetail";
import { IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
export default function SelectQuesteeBox({ questee, addFunction, arrayLength , questInfo, isSelected, removeFunction, type}) {
  const [selectedQuestee, setSelectedQuestee] = useState(false);

  function callAdd() {
    addFunction(questee.questeeId);
  }

  const handleChange = (event) => {
    setSelectedQuestee(event.target.checked);
    if(!selectedQuestee){
     addFunction(event.target.value)
     setSelectedQuestee(!event.target.value)
    }
  };

  return (
    <div className="host-app-profile-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="host-app-info">

        {type != 1&&(isSelected?(<IconButton onClick={() => removeFunction(questee?.questeeId)}>
            <CloseIcon style={{ color: "red" }} />
          </IconButton>):(
        <FormControlLabel control={<Checkbox checked={selectedQuestee} onChange={handleChange} value={questee.questeeId}/>}/>))}
          <Avatar
            sx={{ width: 30, height: 30, marginRight: "8px" }}
            alt=""
            src={`data:image/jpeg;base64,${questee.questeePicture}`}
          />
          <h1>
            {questee.questeeName} | {questee.country}
          </h1>
        </div>
        {type != 1&&isSelected&&( <h2 style={{ color: "#E4610F" }}>
          <CheckCircleOutlineIcon fontSize="18px" /> Selected
        </h2>)}
        <HostApprovalDetail questee={questee} callAdd={callAdd} arrayLength={arrayLength} questInfo={questInfo} type={type}/>
      </div>
    </div>
  );
}
