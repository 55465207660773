import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import tablet from "../../assets/landingPage/tablet.png";
import vector1 from "../../assets/landingPage/Vector1.png";
import vector2 from "../../assets/landingPage/Vector2.png";
import vector3 from "../../assets/landingPage/Vector3.png";
import lovinklaan from "../../assets/landingPage/lovinklaan.png";
import arcadis from "../../assets/landingPage/arcadis.png";
import quest from "../../assets/landingPage/Logo.png";
import { useSelector } from "react-redux";
import { Button } from "@mui/material"

export default function LandingPage() {
  const navigate = useNavigate();
  const questData = useSelector((state) => state.questPersistReducer);


  return (
    <>
      <div className="landing-page">
        <div className="imagem1-div">
          <div className="landing-header">
            <img src={quest} alt="quest" />
          </div>
          <div className="first-text-div">
            <h1 className="welcome">Welcome to the</h1>
            <h1 className="program">Quest Program</h1>
            <h3 className="explanation1">
              An international knowledge transfer program, powered by
            </h3>
            <h3 className="explanation2">Lovinklaan Foundation</h3>
            <div className="buttons">
              {(questData.traditionalQuestOpen ||
                questData.gbaQuestOpen ||
                questData.hostQuestOpen) && (
                <Button
                  style={{ backgroundColor: "#E4610F" }}
                  variant="secondary"
                  className={"login-button-landing"}
                  onClick={() => navigate("/quests/dashboard")}
                  id="bttn"
                >
                  APPLY NOW
                </Button>
              )}
              <a
                target="_blank"
                href="https://arcadiso365.sharepoint.com/teams/QuestCommunity?xsdata=MDV8MDF8fGEwMDNlYmE5ZWYxMzRlOTQxZWNkMDhkYjcyN2JkYmRjfDdmOTAwNTdkM2VhMDQ2ZmViMDdjZTA1Njg2MjcwODFifDB8MHw2MzgyMjk2NDg0OTgyMzcxMzN8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalk0TjJZd1pUaGxMV1ZsT0RNdE5HSTFNUzFoWm1JekxXRmxNekF6WWpnNE5UZGpaVjlrWldJeE5qa3lPQzAyTkRRNUxUUTBNemN0T0RVM01pMWpOalEzWmpnek16WTVOelJBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVFk0TnpNMk9EQTBPVEUyTnc9PXwwMTc0NTE5YjRmNjg0ZjBiMWVjZDA4ZGI3MjdiZGJkY3w4MTc3NjBlZmY0Y2Q0ZjE5OGFjZjU1MDFmYmFiNTJhYw%3D%3D&sdata=NFVaSmdmaEh1V3dIcHNYb2lKSmZuTnJtdFVyVTZtRStrNnJON05wTXRBdz0%3D&ovuser=7f90057d-3ea0-46fe-b07c-e0568627081b%2Cjoao.parreira%40arcadislogos.com.br&OR=Teams-HL&CT=1687368062476&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA1MDEwMDQyMiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                style={{
                  textDecoration: "underline",
                  color: "white",
                  whiteSpace: "nowrap",
                }}
              >
                Understand the program
              </a>
            </div>
          </div>
        </div>

        <div className="imagem2-div">
          <div className="imagem2-text">
            <h1>About Quest</h1>
            <p>
              This is your opportunity to travel to another country to share
              expertise and culture, and to learn from other Arcadians,
              expanding your networks and broadening your knowldge.
            </p>
            <div className="steps">
              <div className="step">
                <img src={vector1} alt="vector1" />
                <h3 style={{ marginTop: 10 }}>Step 1</h3>
                <h4>Application</h4>
                <h5>Submit an idea to participate in your own Quest</h5>
              </div>
              <div className="step">
                <img src={vector2} alt="vector2" />
                <h3>Step 2</h3>
                <h4>Review and Feedback</h4>
                <h5>
                  Wait for your line manager, host, and Lovinklaan approval
                </h5>
              </div>
              <div className="step">
                <img src={vector3} alt="vector3" />
                <h3>Step 3</h3>
                <h4>Pack Your Bags!</h4>
                <h5>If your application is approved, congratulations!</h5>
              </div>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://arcadiso365.sharepoint.com/teams/QuestCommunity"
            >
              Check our intranet and learn more about the Quest Program
            </a>
          </div>

          <div>
            <img src={tablet} alt="tablet" style={{ width: "35vw" }} />
          </div>
        </div>
        <div className="footer-landing">
          <div className="footer-text">
            <h1>Powered by Lovinklaan Foundation</h1>
            <a
              style={{ fontSize: "18px" }}
              target="_blank"
              href="https://www.lovinklaan.nl/en/"
            >
              www.lovinklaan.nl
            </a>
          </div>
          <div className="logos">
            <img src={lovinklaan} alt="lovinklaan" />
            <img src={arcadis} alt="arcadis" />
          </div>
        </div>
      </div>
    </>
  );
}
