import './QuestTypeTag.css';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonIcon from '@mui/icons-material/Person';

export default function estyQuestTypeTag({maxQt}){
    return(
        <div className='tag'>
            {maxQt > 1  ? 
                <div className='chip'><GroupAddIcon fontSize='12px'/> Group Quest</div>
            :
                <div className='chip'><PersonIcon fontSize='12px'/> Single Quest</div>

            }
        </div>
    )
}