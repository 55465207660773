import { useDispatch, useSelector } from "react-redux";
import { storeUserData } from "../../store/redux/authSlice";
import { fillDataDynamically } from "../../store/redux/questeeSlice";
import { Alert, DialogActions } from "@mui/material";
import { useState, useEffect } from "react";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";

import TextareaAutosize from "@mui/base/TextareaAutosize";
import Api from "../../services/Api";
import NotePopup from "../../components/NotePopup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ValueToStakeholders() {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { value1, value2, value3, type, questId} =
    useSelector((state) => state.questeePersistReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function populateReduxDynamicallyHandler(value, target) {
    dispatch(
      fillDataDynamically({
        value: value,
        target: target,
      })
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  async function updateQuest(submission) {
    try {
      dispatch(toggleLoadingHandler());

      Api.post(`/quest/updateAll/${questId}`, {
        questeeId: userData.userId,
        onlyQuestions: type == 1 ? false : true,
        userId: userData.userId,
        questions: [
          {
            question: "value_1",
            answer: value1,
          },
          {
            question: "value_2",
            answer: value2,
          },
          {
            question: "value_3",
            answer: value3,
          },
        ],
        isSubmited: submission,
      })
        .then(() => {
          setUpdateSuccess(true);
          if (submission) {
            dispatch(
              storeUserData({
                ...userData,
                questeeStatus: "R",
              })
            );
            populateReduxDynamicallyHandler(true, "submited")
            dispatch(untoggleLoadingHandler());

            window.location.href = "/#/dashboard";
          }
          dispatch(untoggleLoadingHandler());
        })
        .catch((e) => {
          console.log("error", e);

          setErrorMessage(e.response.data);
          handleClickOpen();
        });
    } catch {}
  }

  return (
    <div>
      {errorMessage !== "" ? (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="popup-title">
            {errorMessage.message}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popupu-message"
            >
              {errorMessage?.fields?.length > 0 ? (
                errorMessage.fields.map((el) => {
                  return <p>{el}</p>;
                })
              ) : (
                <></>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <button  className='pop-option1'>hehe</button> */}
            <button
              onClick={() => {
                handleClose();
                dispatch(untoggleLoadingHandler());
              }}
              autoFocus
              className="pop-option2"
            >
              OK
            </button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      <>
        <div className="stakeholder-info">
          <h3>Value to Stakeholders</h3>
          <p>
            This section of the business plan provides detail of the added
            benefit or value that this Quest will deliver to stakeholders,
            including the Host, Questee(s), Arcadis, and any other stakeholders
            identified for the proposed Quest. You may want to consider:
          </p>
          <p>
            <ul>
              <li>
                What specific area of expertise do you want to increase your
                knowledge, skills and experience in?
              </li>
              <li>
                What particular area of expertise is valuable for you to share
                with another region?
              </li>
              <li>
                How does your idea for a Quest support your ongoing personal and
                professional development?
              </li>
            </ul>
          </p>
        </div>
        <div className="text-div">
          <span className="input-label" id="text-label">
            Describe how your Quest ties to the mission, vision, and values of
            Arcadis.
            <span className="mandatory">*</span>
          </span>
          <TextareaAutosize
            defaultValue={value1}
            onChange={(e) => {
              populateReduxDynamicallyHandler(e.target.value, "value1");
            }}
            style={{ width: "85%", height: "192px" }}
          />
          <p style={{ fontSize: "14px", width: "85%" }}>
            The mission, vision, and values of Arcadis can be found on the
            Intranet or at{" "}
            <a href="https://www.arcadis.com/en/about-us" target="_blank">
              https://www.arcadis.com/en/about-us
            </a>
          </p>
        </div>
        <div className="text-div">
          <span id="text-label" className="input-label">
            Identify areas of expertise and knowledge that the Host and
            Questee(s) will share with each other.{" "}
            <span className="mandatory">*</span>
          </span>
          <TextareaAutosize
            value={value2}
            onChange={(e) => {
              populateReduxDynamicallyHandler(e.target.value, "value2");
            }}
            style={{ width: "85%", height: "192px" }}
          />
        </div>
        <div className="text-div">
          <span id="text-label" className="input-label">
            Explain how participating in the Quest will support Host/Questee
            career development. <span className="mandatory">*</span>
          </span>
          <TextareaAutosize
            value={value3}
            onChange={(e) => {
              populateReduxDynamicallyHandler(e.target.value, "value3");
            }}
            style={{ width: "85%", height: "192px" }}
          />
        </div>

        {updateSuccess === true && (
          <div className="input-div-quest-form">
            <Alert
              style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
              className="large-input"
              severity="success"
            >
              Your application has been saved but has not been submitted.
            </Alert>
          </div>
        )}

        <div
          style={{
            paddingBottom: "32px",
            paddingLeft: "7.5%",
            paddingRight: "7.5%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <button
            className="next-button"
            id="previous"
            onClick={() => {
              populateReduxDynamicallyHandler(1, "formIndex");
            }}
          >
            Previous
          </button>
          {questId !== "" && (
            <button
              className="next-button"
              onClick={() => {
                // window.location.href = "/#/dashboard";
                updateQuest(false);
              }}
            >
              Save and continue later
            </button>
          )}
          <NotePopup
            action2={() => {
              updateQuest(true);
            }}
            titleMessage="Once you submit your application, no further changes can be made."
            option2="Send application and go to dashboard"
            classOption="send-button"
            title="Submit my Quest application"
          />
        </div>
      </>
    </div>
  );
}
