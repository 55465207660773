import "./QuestCard.css";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import QuestInformationModal from "../QuestInformationModal";
import QuestTypeTag from "../QuestTypeTag/indx";
import { useSelector } from "react-redux";


export default function QuestCard({ questInfo }) {
  const questData = useSelector((state) => state.questPersistReducer);
    return (
    <>
      <div className="quest-card">
        <div className="buttons-header">
          <div className="header-tag">
            <h3>{questInfo.gba}</h3>
          </div>
          <div className="quest-count">
            <span>
              <PeopleAltOutlinedIcon style={{ fontSize: "medium" }} />{" "}
              {questInfo.qtyCandidates}/{questInfo.maxQty}  on this Opportunity
            </span>
          </div>
        </div>
        <div className="quest-info">
          <h1>{questInfo.Title}</h1>
           <p>{questInfo?.questions?.find(q=>q.question=='details')?questInfo?.questions?.find(q=>q.question=='details').answer:questInfo?.questions?.find(q=>q.question=='objective_1')?questInfo?.questions?.find(q=>q.question=='objective_1').answer:""}</p>

           <p>
            {questInfo.complete == false? "Coming soon":""}
           </p>

          <div className="quest-location">
            <div className="card-location-tag">
              <span>Quest location</span>
              <span>{questInfo.office}</span>
            </div>
            <div className="card-location-tag">
              <span>Host email</span>
              <span>{questInfo.hostEmail}</span>
            </div>
          </div>
          <div className="bottom-tags">
            <QuestTypeTag maxQt={questInfo.maxQty} />
            {(questData.gbaQuestOpen || questData.hostQuestOpen) && (
              <QuestInformationModal questInfo={questInfo} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
