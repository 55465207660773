import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: 0,
    backgroundColor:
      'white',
    borderRadius: 1,
    
  },
}));
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: 'white',
  zIndex: 1,
  color: 'black',
  border: 'solid',
  borderColor: 'grey',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: 'white',
    borderColor: '#E4610F'
  }),
  ...(ownerState.completed && {
      backgroundColor: '#E4610F',
      borderColor: '#E4610F',
      color: 'white',

  }),
  marginTop: '10px',
  marginRight: '10px'
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: '1',
    2: '2',
    3: '3',
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Location of Quest', 'Objectives', 'Value to Stakeholder'];

export default function StepperComponent({activeStep}) {
  return (
    <Stack sx={{ width: '90%', paddingTop: '32px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', borderBottom: '1px solid #ccc;', marginLeft: '5%'}} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <StepLabel style={{display: 'flex', flexDirection: 'row', width: 'fit-content'}} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
