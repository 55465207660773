import "./HostApprovalPage.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProfileComponent from "../../components/Dashboard/ProfileComponent";
import Header from "../../components/Header";
import HostCard from "../../components/HostCard";
import LineManagerTabs from "../../components/LineManagerTabs";
import Api from "../../services/Api";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";
import { useCallback } from "react";

export default function HostApprovalPage() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const pendingQuests = [];
  const completedQuests = [];
  const [quests, setQuests] = useState([]);

  const getHostQuests = useCallback(async () => {
    dispatch(toggleLoadingHandler());

    try {
      const response = await Api.get(`/approval/host/${userData.userId}`);
      setQuests(response.data);
    } catch (err) {
      console.log(err);
    }
    dispatch(untoggleLoadingHandler());
  }, []);

  useEffect(() => {
    getHostQuests();
  }, [getHostQuests]);

  quests.forEach((quest) => {
    const { status } = quest;
    if (status === "approved" || status === "rejected") {
      completedQuests.push(quest);
    } else {
      pendingQuests.push(quest);
    }
  });

  return (
    <>
      <div className="host-approval-main-container">
        <Header />
        <div className="host-data">
          <div className="divfix">
            <div className="form-header">
              <h1>Hi, Host!</h1>
              <p>The Questees below are waiting for your approval!</p>
            </div>
            <div style={{ padding: "0px 60px 20px 60px" }}>
              {/* <div className="input-div-double">
              <span className="input-label">Filter By</span>
              <Select
                className="dropdown"
                onChange={(e) => console.log(e)}
                // value={userData.onboardingCompleted? userData.arcadisYears:""}
              >
                <MenuItem value={"teste"}>teste</MenuItem>
              </Select>
            </div> */}
              <LineManagerTabs
                title1="Opportunities"
                element1={
                  pendingQuests.length ? (
                    pendingQuests.map((quest, index) => (
                      <div key={index}>
                        <HostCard quest={quest} updateData={()=>null}/>
                      </div>
                    ))
                  ) : (
                    <></>
                  )
                }
                title2="Completed Opportunities"
                element2={
                  completedQuests.length ? (
                    completedQuests.map((quest, index) => (
                      <div key={index}>
                        <HostCard quest={quest} key={index} updateData={()=>null}/>
                      </div>
                    ))
                  ) : (
                    <></>
                  )
                }
              />
            </div>

            {/* {pendingQuests.map((quest) => {})} */}
          </div>
          <div
            style={{ paddingLeft: "40px", paddingRight: "40px", width: "100%" }}
          >
            <ProfileComponent />
          </div>
        </div>
      </div>
    </>
  );
}
