export const arcadisCountries = [
  { id: 1, name: "Australia" },
  { id: 2, name: "Bahrain" },
  { id: 3, name: "Belgium" },
  { id: 4, name: "Brazil" },
  { id: 5, name: "Canada" },
  { id: 6, name: "Chile" },
  { id: 7, name: "China" },
  { id: 8, name: "France" },
  { id: 9, name: "Germany" },
  { id: 10, name: "India" },
  { id: 11, name: "Ireland" },
  { id: 12, name: "Italy" },
  { id: 13, name: "Kingdom of Saudi Arabia" },
  { id: 14, name: "Mexico (IBI)" },
  { id: 15, name: "Netherlands" },
  { id: 16, name: "Oman" },
  { id: 17, name: "Philippines" },
  { id: 18, name: "Poland" },
  { id: 19, name: "Puerto Rico" },
  { id: 20, name: "Qatar" },
  { id: 21, name: "Romania" },
  { id: 22, name: "Spain" },
  { id: 23, name: "Turkey" },
  { id: 24, name: "United Arab Emirates" },
  { id: 25, name: "United Kingdom" },
  { id: 26, name: "United States" },
];
