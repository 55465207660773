import "./Header.css";
import logoQuest from "../../assets/images/logoQuest.svg";
import { useEffect, useState } from "react";
import MenuComponent from "../Menu";

export default function Header() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      <div className="app-header">
        <div>
          <a className="header-menu-item" href="/#/quests/dashboard">
            <img alt="quest logo" src={logoQuest} width="180"></img>
          </a>
        </div>
        {/* {windowSize > 766 ? */}
        {/* <div className='navigation-links'> */}
        <MenuComponent />
        {/* </div> */}
      </div>
    </>
  );
}
