import "./ApplicationPages.css";
import { useState } from "react";
import { arcadisCountries } from "../../assets/countries/arcadisCountries";
import {
  Alert,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { months } from "../../assets/months/months";
import { useDispatch, useSelector } from "react-redux";
import { fillDataDynamically } from "../../store/redux/questeeSlice";
import { newQuestFormHandler } from "../../store/redux/authSlice";
import { openModal } from "../../store/redux/modalSlicer";

import Api from "../../services/Api";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PeoplePicker from "../../components/PeoplePicker";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";


export default function LocationOfQuest() {
  const {
    value1,
    value2,
    value3,
    objective1,
    objective1Description,
    objective2,
    objective2Description,
    objective3,
    objective3Description,
    notFlexibelDate,
    country,
    office,
    hostEmail,
    month,
    year,
    flexibelDate,
    holidaysConflict,
    holidayHasConflict,
    invalidForm,
    user,
    questId,
    type,
  } = useSelector((state) => state.questeePersistReducer);

  const questData = useSelector((state) => state.questPersistReducer);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  console.log(questData)

  const userData = useSelector((state) => state.authPersistReducer.userData);
  const dispatch = useDispatch();

  function dispatchToQuesteeReduxHandler(value, target) {
    dispatch(
      fillDataDynamically({
        value: value,
        target: target,
      })
    );
  }

  function validateEmail(manager) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(manager);
  }

  const handleEmailChange = () => {
    if (
      hostEmail !== undefined &&
      (hostEmail !== "" && hostEmail !== userData.email)
    ) {
      const validation = validateEmail(hostEmail);
      setIsEmailValid(validation);
    }else{
      setIsEmailValid(false);
    }
  };

  const actualYear = new Date().getFullYear();
  const yearsOptions = [{ value: actualYear }, { value: actualYear + 1 }];
  const formValidation = () => {
    if (
      country !== "" &&
      office !== "" &&
      year !== "" &&
      month !== "" &&
      flexibelDate !== "" &&
      holidaysConflict !== "" &&
      hostEmail !== "" &&
      hostEmail !== userData.email
    ) {
      dispatchToQuesteeReduxHandler(false, "invalidForm");

      dispatchToQuesteeReduxHandler(1, "formIndex");
    } else {
      dispatchToQuesteeReduxHandler(true, "invalidForm");
    }
  };

  if (type != 1) {
    dispatchToQuesteeReduxHandler(1, "formIndex");
  }

  async function updateQuest() {
      try {
        await Api.post(`/quest/updateAll/${questId}`, {
          country,
          office,
          year,
          month,
          dateFlexible: flexibelDate,
          reason: notFlexibelDate,
          holidaysConflict: holidaysConflict,
          questeeId: userData.userId,
          userId: userData.userId,
          hostEmail: hostEmail,
          reasonsForHoliday: holidayHasConflict,
          cycleId:questData.traditionalQuestId ,
          questions: [
            {
              question: "objective_type_1",
              answer: objective1,
              type: null,
            },
            {
              question: "objective_1",
              answer: objective1Description,
              type: null,
            },
            {
              question: "objective_type_2",
              answer: objective2,
              type: null,
            },
            {
              question: "objective_2",
              answer: objective2Description,
              type: null,
            },
            {
              question: "objective_type_3",
              answer: objective3,
              type: null,
            },
            {
              question: "objective_3",
              answer: objective3Description,
              type: null,
            },
            {
              question: "value_1",
              answer: value1,
            },
            {
              question: "value_2",
              answer: value2,
            },
            {
              question: "value_3",
              answer: value3,
            },
          ],
        });
        setUpdateSuccess(true);
      } catch {}
  }

  async function newQuestForm() {
    dispatch(toggleLoadingHandler());

    if (!invalidForm) {
      try {
        let response = await Api.post("/quest/create", {
          country,
          office,
          year,
          month,
          dateFlexible: flexibelDate,
          reason: notFlexibelDate,
          holidaysConflict: holidaysConflict,
          questeeId: userData.userId,
          hostEmail: hostEmail,
          reasonsForHoliday: holidayHasConflict,
          type: 1,
          cycleId:questData.traditionalQuestId,
          questions: [
            {
              question: "objective_type_1",
              answer: "",
              label: "Objective 1",
            },
            {
              question: "objective_1",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 1",
            },
            {
              question: "objective_type_2",
              answer: "",
              label: "Objective 2",
            },
            {
              question: "objective_2",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 2",
            },
            {
              question: "objective_type_3",
              answer: "",
              label: "Objective 3",
            },
            {
              question: "objective_3",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 3",
            },
            {
              question: "value_1",
              answer: "",
              label:
                "Describe how your Quest ties to the mission, vision, and values of Arcadis",
            },
            {
              question: "value_2",
              answer: "",
              label:
                "Indentify areas of expertise and knowledge that the Host and Questee(s), will share with each other",
            },
            {
              question: "value_3",
              answer: "",
              label:
                "Explain how participating in the Quest will support Host/Questee career development",
            },
          ],
        });

        dispatchToQuesteeReduxHandler(response.data.quest.questId, "questId");
        dispatchToQuesteeReduxHandler(1, "type");
        dispatch(newQuestFormHandler({ ...userData, questeeStatus: "P" }));
        dispatchToQuesteeReduxHandler(1, "formIndex");
        dispatch(untoggleLoadingHandler());
        return;
      } catch (error) {
        console.log(error);
        dispatch(
          openModal({
            title: "Error",
            message: "Error while sending your request, please try again",
          })
        );
        dispatch(untoggleLoadingHandler());

        return;
      }
    }
  }

  return (
    <div style={{ padding: "32px" }}>
      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label-application">
            What country would you like to travel to?{" "}
            <span className="mandatory">*</span>{" "}
          </span>
        </div>

        <Select
          style={{ width: "90%", marginLeft: "5%" }}
          value={country}
          onChange={(e) => {
            dispatchToQuesteeReduxHandler(e.target.value, "country");
          }}
          className="dropdown"
        >
          {arcadisCountries.map((country) => (
            <MenuItem value={country.name} key={country.id}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      {invalidForm && country === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label-application" id="office">
            What city would you like to tavel to?
            <span className="mandatory">*</span>
          </span>
        </div>
        <TextField
          value={office}
          placeholder="Enter Office"
          style={{ width: "90%", marginLeft: "5%" }}
          onChange={(e) => {
            dispatchToQuesteeReduxHandler(e.target.value, "office");
          }}
          className="large-input"
        ></TextField>
        <span id="info" className="info-label">
          enter the city and state of the office you would like to travel to.
        </span>
      </div>

      {invalidForm && office === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label">
            Host Email <span className="mandatory">*</span>
          </span>
        </div>
        <div className="large-input">
          <PeoplePicker
            defaultValue={hostEmail ? hostEmail : ""}
            onChangeFunction={(data) => {
              handleEmailChange(hostEmail);
              dispatchToQuesteeReduxHandler(data, "hostEmail");
            }}
          />
        </div>
      </div>

      {invalidForm && hostEmail === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      {hostEmail === userData.email && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            You can't be the questee and the host for the quest.
          </Alert>
        </div>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label-application">
            Month of travel <span className="mandatory">*</span>{" "}
          </span>
        </div>

        <Select
          style={{ width: "90%", marginLeft: "5%" }}
          defaultValue={month}
          onChange={(e) => {
            dispatchToQuesteeReduxHandler(e.target.value, "month");
          }}
          className="dropdown"
        >
          {months.map((month) => (
            <MenuItem value={month.value} key={month.value}>
              {month.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      {invalidForm && month === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label-application">
            Year of travel <span className="mandatory">*</span>{" "}
          </span>
        </div>

        <Select
          style={{ width: "90%", marginLeft: "5%" }}
          defaultValue={year}
          onChange={(e) => {
            dispatchToQuesteeReduxHandler(e.target.value, "year");
          }}
          className="dropdown"
        >
          {yearsOptions.map((year, index) => (
            <MenuItem value={year.value} key={index}>
              {year.value}
            </MenuItem>
          ))}
        </Select>
      </div>

      {invalidForm && year === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label" style={{ width: "100%" }}>
            Are your travel dates flexible?
            <span className="mandatory">*</span>
          </span>
        </div>
        <RadioGroup className="radio-group" defaultValue={flexibelDate}>
          <FormControlLabel
            className={
              flexibelDate === true ? "radio-div-selected" : "radio-div"
            }
            value={true}
            onChange={(e) => {
              dispatchToQuesteeReduxHandler(true, "flexibelDate");
            }}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            className={
              flexibelDate === false ? "radio-div-selected" : "radio-div"
            }
            value={false}
            onChange={(e) => {
              dispatchToQuesteeReduxHandler(false, "flexibelDate");
            }}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </div>

      {invalidForm && flexibelDate === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      {flexibelDate === false && (
        <>
          <div className="label">
            <span className="input-label" style={{ width: "100%" }}>
              Please describe the need for the specific timing
              <span className="mandatory">*</span>
            </span>
          </div>
          <div style={{ width: "100%", paddingLeft: "5%" }}>
            <TextareaAutosize
              defaultValue={notFlexibelDate}
              onChange={(e) => {
                dispatchToQuesteeReduxHandler(
                  e.target.value,
                  "notFlexibleDate"
                );
              }}
              minRows={5}
              style={{ width: "90%", height: "192px" }}
            />
          </div>
        </>
      )}

      <div className="input-div-quest-form">
        <div className="label">
          <span className="input-label" style={{ width: "100%" }}>
            Do your travel dates conflict with holidays in the Host region that
            may impact the Quest schedule?
            <span className="mandatory">*</span>
          </span>
        </div>
        <span id="info" className="info-label">
          Examples include Ramadan, Christmas, Chinese New Year, etc.
        </span>
        <RadioGroup
          defaultValue={holidaysConflict}
          className="radio-group"
          style={{ marginTop: "20px" }}
        >
          <FormControlLabel
            className={
              holidaysConflict === true ? "radio-div-selected" : "radio-div"
            }
            onChange={(e) => {
              dispatchToQuesteeReduxHandler(true, "holidaysConflict");
            }}
            value={true}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            className={
              holidaysConflict === false ? "radio-div-selected" : "radio-div"
            }
            value={false}
            onChange={(e) => {
              dispatchToQuesteeReduxHandler(false, "holidaysConflict");
            }}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </div>

      {holidaysConflict === true && (
        <>
          <div className="label">
            <span className="input-label" style={{ width: "100%" }}>
              Please describe the need for the specific timing
              <span className="mandatory">*</span>
            </span>
          </div>

          <div style={{ width: "100%", paddingLeft: "5%" }}>
            <TextareaAutosize
              defaultValue={holidayHasConflict}
              onChange={(e) => {
                dispatchToQuesteeReduxHandler(
                  e.target.value,
                  "holidayHasConflict"
                );
              }}
              minRows={5}
              style={{ width: "90%", height: "192px", marginRight: "2%" }}
            />
          </div>
        </>
      )}

      {invalidForm && holidaysConflict === "" && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="error"
          >
            This field is required.
          </Alert>
        </div>
      )}

      {updateSuccess === true && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="success"
          >
            Your application has been saved but has not been submitted.
          </Alert>
        </div>
      )}

      <div className="buttons-div">
      {hostEmail !== userData.email &&(
        questId ? (
          <>
            <button
              className="next-button"
              onClick={() => {
                updateQuest(user);
              }}
            >
              Save
            </button>
            <button
              className="next-button"
              onClick={() => {
                updateQuest(user);
                dispatchToQuesteeReduxHandler(1, "formIndex");
              }}
            >
              Next
            </button>
          </>
        ) : (
          <>
            <button
              className="next-button"
              onClick={() => {
                formValidation();
                newQuestForm(user);
                setUpdateSuccess(false);
              }}
            >
              Create my application
            </button>
          </>
        ))}
      </div>
    </div>
  );
}
