export default function checkDuplicatedCountries(
  questees,
  reduxDuplicateCountryHandler
) {
  const keys = new Set();

  reduxDuplicateCountryHandler(false, "duplicatedCountry");
  // Iterate over each element in the array
  for (let i = 0; i < questees.length; i++) {
    const country = questees[i].country;

    // Check if the key already exists in the set
    if (keys.has(country)) {
      reduxDuplicateCountryHandler(true, "duplicatedCountry"); // Found a duplicate key
    }

    // Add the key to the set
    keys.add(country);
    // setDuplicatedCountry(false);
  }
  // No duplicate keys found
}
