import "./DashBoardComponent.css";
import { Alert } from "@mui/material";
import { useSelector } from "react-redux";
import DashboardAccordion from "../DashboardAccordion";
import DashboardStepper from "../DashboardStepper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StatusTag from "../../StatusTag";


export default function DashboardComponent(props) {
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const questeeData = useSelector((state) => state.questeePersistReducer);
  const questData = useSelector((state) => state.questPersistReducer);


  const {
    country,
    office,
    hostEmail,
    objective1,
    objective1Description,
    objective2,
    objective2Description,
    objective3,
    objective3Description,
    value1,
    value2,
    value3,
    questId,
    status, 
    questStep
  } = questeeData


  return (
    <div>
      {props.isUser === true ? (
        <div className="dash-component-container">
          <div className="form-header-dash">
            <h1>Hi, {props.name}!</h1>
            <p>{props.introText}</p>
          </div>



          { (questData.traditionalQuestOpen || questData.gbaQuestOpen || questData.hostQuestOpen) && questId && (
            <Alert
              severity={
                status === "pending"
                  ? "warning"
                  : status === "approved"
                  ? "success"
                  : "warning"
              }
              style={{
                marginTop: "24px",
                marginBottom: "24px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <span className="alert1">
                {status === "pending"
                  ? `Waiting for ${questStep}`
                  : status === "approved"
                  ? "Approved"
                  : userData.questeeStatus === "P" ? "Pending finishing submission": ""}
              </span>
              { userData.questeeStatus === "P" && (
                  <span>
                    <button
                      className="button-complete-quest-dash"
                      onClick={() => {
                        window.location.href = "/#/quest/application";
                      }}
                    >
                      click here to continue
                    </button>
                  </span>
                )}
            </Alert>
          )}
          <div className="application-info-dashboard">
            <div className="info-div">
              <StatusTag
                status={status}
                // responsible={
                //   props.userInfo.Quests[0].QuesteeInfos[0].questeeStep
                // }
              />
              <span className="title">Quest location</span>
              <span className="location">
                <LocationOnIcon />
                {country} ({office})
              </span>
            </div>
            <div className="info-div">
              <span className="title">Host email</span>
              <span className="location">{hostEmail}</span>
            </div>
            <div>
              <span className="title">Quest objectives</span>
              <DashboardAccordion
                title={objective1}
                info={objective1Description}
              />
              <DashboardAccordion
                title={objective2}
                info={objective2Description}
              />
              <DashboardAccordion
                title={objective3}
                info={objective3Description}
              />
            </div>
            <div>
              <span className="title">Quest details</span>
              <div className="detail">
                <span className="input-title">
                  Describe how your Quest ties to the mission, vision, and
                  values of Arcadis.
                </span>
                <div className="text-div-dash">
                  <p>{value1}</p>
                </div>
              </div>
              <div className="detail">
                <span className="input-title">
                  Identify areas of expertise and knowledge that the Host and
                  Questee(s) will share with each other.
                </span>
                <div className="text-div-dash">
                  <p>{value2}</p>
                </div>
              </div>
              <div className="detail">
                <span className="input-title">
                  Explain how participating in the Quest will support
                  Host/Questee career development.
                </span>
                <div className="text-div-dash">
                  <p>{value3}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="form-header">
            <h1>Hi, {props.name}!</h1>
            <p>{props.introText}</p>
          </div>
          <div className="application-info">
            <DashboardStepper />
          </div>
        </>
      )}
    </div>
  );
}
