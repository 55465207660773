export default function StatusTag({ status, responsible }) {
  return (
    <div
      className={
        status === "pending"
          ? "analyzing-tag"
          : status === "approved"
          ? "approved-tag"
          : status === "rejected"
          ? "rejected-tag"
          : "analyzing-tag"
      }
    >
      {status === "pending"
        ? "ANALYZING"
        : status === "approved"
        ? "APPROVED"
        : status === "rejected"
        ? "REJECTED"
        : "PENDING"}
    </div>
  );

  // if (status === "pending" && !responsible) {
  //   return <div className="analyzing-tag">ANALYZING</div>;
  // }
  // if (status === "approved") {
  //   return <div className="approved-tag">APPROVED</div>;
  // }
  // if (status === "rejected") {
  //   return <div className="rejected-tag">REJECTED</div>;
  // }
  // if (status === "On hold") {
  //   return <div className="analyzing-tag">PENDING</div>;
  // }

  // if (responsible) {
  //   if (status === "pending" && responsible === "Manager approval") {
  //     return (
  //       <div className="analyzing-tag">Waiting for Line Manager approval</div>
  //     );
  //   }
  //   if (status === "approved") {
  //     return <div className="approved-tag">APPROVED</div>;
  //   }
  //   if (status === "pending" && responsible === "Host approval") {
  //     return <div className="analyzing-tag">Waiting for Host approval</div>;
  //   }
  //   if (status === "On hold") {
  //     return <div className="analyzing-tag">PENDING</div>;
  //   }
  // }
}
