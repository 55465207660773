import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/global.css";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./store/store";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { HashRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import OnboardingPage from "./pages/OnboardingPage/index";
import QuestApplicationPage from "./pages/QuestApplicationPage";
import Dashboard from "./pages/Dashboard";
import LineManagerDashboard from "./pages/LineManagerDashboard";
import PageNotAllowedMessage from "./components/PageNotAllowedMessage";
import LovinklaanDashboard from "./pages/LovinklaanDashboard";
import QuestsDashboard from "./pages/QuestsDashboard";
import HostApprovalPage from "./pages/HostApprovalPage";
import LoginPage from "./pages/LoginPage";
import Modal from "./components/Modal";
import LoadingIcon from "./components/LoadingIcon";
import Layout from "./pages/Layout";


function MySnackbar() {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        <div>
        If you encounter unexpected behavior, please consider refreshing the page, opening it in another tab, or clearing your cache. If the problem persists, feel free to contact us.
        </div>
        <div>
       If you were registered on the platform before and have less than a year of experience with Arcadis, please remember to update your profile at <a href="https://arcadisquest.com/#/onboarding">https://arcadisquest.com/#/onboarding</a>
       </div>
      </Alert>
    </Snackbar>
  );
}



let persistor = persistStore(store);
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));



localStorage.clear();


root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <HashRouter>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Modal />
              <LoadingIcon />
              <MySnackbar />
              <Layout >
                <Routes>
                  <Route exact="true" path="/" element={<LandingPage />} />
                  <Route path="/onboarding" element={<OnboardingPage />} />
                  <Route
                    path="/quest/application"
                    element={<QuestApplicationPage />}
                  />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/linemanager/dashboard"
                    element={<LineManagerDashboard />}
                  />
                  <Route
                    path="/lovinklaan/dashboard"
                    element={<LovinklaanDashboard />}
                  />
                  <Route
                    path="/unauthorized"
                    element={<PageNotAllowedMessage />}
                  />
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    exact="true"
                    path="/quests/dashboard"
                    element={<QuestsDashboard />}
                  />
                  <Route
                    exact="true"
                    path="/host/approval"
                    element={<HostApprovalPage />}
                  />
                </Routes>
              </Layout>
            </PersistGate>
          </Provider>
        </HashRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);
