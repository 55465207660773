import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import QuestCard from "../../components/QuestCard";
import "./QuestsDashboard.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Api from "../../services/Api";
import NoQuestsToReviewMessage from "../../components/NoQuestsToReviewMessage";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";
import { reduxApplyToQuest } from "../../store/redux/questeeSlice";

import QuestInformationModal from "../../components/QuestInformationModal";
import PoliciesModal from "../../components/PoliciesModal";
import NotePopup from "../../components/NotePopup";

export default function QuestsDashboard() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const questData = useSelector((state) => state.questPersistReducer);
  const [questsOp, setQuestsOp] = useState([]);
  const navigate = useNavigate();

  async function applyToQuest() {
    dispatch(
      reduxApplyToQuest({
        formIndex:0,
        questApplication:true,
        type:1
      })
    );
  }

  useEffect(() => {
    fetchQuests();
  }, []);

  async function fetchQuests() {
    dispatch(toggleLoadingHandler());

    try {
      const quests = await Api.get("/quest/questeeOpportunities");
      dispatch(untoggleLoadingHandler());

      setQuestsOp(quests.data);
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div className="wrapper-quests-dash">
      <Header />
      <div className="quests-dashboard-page">
        <div className="hello-message">
          <h1 className="hello-message">Hello, {userData.name}!</h1>
          <p className="hello-message">
            You don’t have a Quest application yet.
          </p>
        </div>

        <div className="find-quests">
          <h3>
            {questData.gbaQuestOpen? `Learn more about ${questsOp.length} Quests Opportunities already
            created by GBA leadership`:questData.hostQuestOpen? `Learn more about ${questsOp.length} Quests Opportunities already
            created by Hosts`: questData.traditionalQuestOpen? "This application cycle is for a traditional Quests only. A traditional Quest is when there is 1 Questee and 1 Host.":"The applications are closed at this moment"}
          </h3>

<NotePopup
              // action2={() => {
              //   applyToQuest(questInfo.questId);
              // }}
              block={
                !questData.traditionalQuestOpen
             }
             style={{
               opacity:
               questData.traditionalQuestOpen? 1: 0.3,
             }}
              titleMessage="You can only submit one application! Are you sure you want to continue?"
              option2={
                <PoliciesModal
                  func={() => {
                    applyToQuest();
                  }}
                  element={<>Apply to this opportunity</>}
                />
              }
              classOption="send-button"
              title={questsOp.length> 1?"OR click here if you already know your Host":"Click here to submit an application"}
            />
{/* 
            OR click here if you already know your Host <ArrowForwardIosIcon />
          </button> */}
        </div>
        {/* 
            <div className="search-quests">
                <div className="search-holder">
                    <p>Search by key word</p>
                    <input></input>
                </div>
                <div className="search-holder">
                    <p>
                        Filter by
                    </p>
                    <div className="filter-select-div">
                        <Select className="filter-select"/>
                        <Select className="filter-select"/>
                        <Select className="filter-select"/>
                    </div>
                </div>
            </div> */}

        {/* isso vai ser o botao p modal */}
        {/* <button className='quests-by-gba'>Predefined Quests by GBA <ArrowForwardIosIcon/></button>  */}
        <div className="quest-cards">
          {questsOp.length > 0 ? (
            questsOp.map((quest) => (
              <div key={quest.questId}>
                <QuestCard questInfo={quest} key={quest.questId} />
              </div>
            ))
          ) : (
            <div className="container">
              {/* <NoQuestsToReviewMessage title="No opportunities at this time. Please, wait for the next opening" /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
