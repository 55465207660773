import { Alert } from "@mui/material";
import { useEffect } from "react";
import Header from "../../components/Header";
import StepperComponent from "../../components/Stepper";
import LocationOfQuest from "../LocationOfQuest/LocationOfQuest";
import ObjectivesPage from "../ObjectivesPage/index";
import ValueToStakeholders from "../ValueToStakeholders/index";

import "./QuestApplicationPage.css";
import { useSelector } from "react-redux";

export default function QuestApplicationPage() {
  const { invalidForm, formIndex } = useSelector(
    (state) => state.questeePersistReducer
  );

  const userData = useSelector((state) => state.authPersistReducer.userData);
  
  const formPages = [
    <LocationOfQuest />,
    <ObjectivesPage />,
    <ValueToStakeholders />,
  ];
  const completedQuestionsFormPages = [
    <LocationOfQuest />,
    <ObjectivesPage />,
    <ValueToStakeholders />,
  ];
  useEffect(() => {
    document.title = "Quest - Questee Application";
  }, [userData]);

  return (
    <>
      <Header />
      <div className="quest-page">
        {userData?.questeeConditionsAgreed === true ? (
          <>
            <div className="form-header">
              <h1>You're applying for a Quest</h1>
              <p>
                Great, {userData.name}! You are about to embark on a
                once-in-a-career experience, meet amazing people, and begin
                forming lasting connections. By completing a Quest, you are
                joining an incredible group of Quest Alumni who are making a
                difference all around the world.
              </p>
            </div>
            <div className="quest-form">
              {invalidForm && (
                <Alert
                  severity="error"
                  style={{
                    width: "85%",
                    marginLeft: "7.5%",
                    marginTop: "58px",
                  }}
                >
                  There was a problem with your submission. Please review the
                  fields below.
                </Alert>
              )}
              <StepperComponent activeStep={formIndex} />
              {formPages[formIndex]}
            </div>
          </>
        ) : (
          <>
            <div className="form-header">
              <h1>You are applying for a Quest</h1>
              <p>
                Great, {userData.name}! You are about to embark on a
                once-in-a-career experience, meet amazing people, and begin
                forming lasting connections. By completing a Quest, you are
                joining an incredible group of Quest Alumni who are making a
                difference all around the world.
              </p>
            </div>
            <div className="quest-form">
              {invalidForm && (
                <Alert
                  severity="error"
                  style={{
                    width: "85%",
                    marginLeft: "7.5%",
                    marginTop: "58px",
                  }}
                >
                  There was a problem with your submission. Please review the
                  fields below.
                </Alert>
              )}
              <StepperComponent activeStep={formIndex} />
              {completedQuestionsFormPages[formIndex]}
            </div>
          </>
        )}
      </div>
    </>
  );
}
