import { createSlice } from "@reduxjs/toolkit";

const modalSlicer = createSlice({
  name: "modalSlicer",
  initialState: {
    modalState: { visible: "none" },
    title: "",
    message: "",
    displayItem: "",
  },
  reducers: {
    openModal: (state, action) => {
      state.modalState = { visible: "block" };
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.displayItem = action.payload.displayItem;
    },
    closeModal: (state, action) => {
      state.modalState = { visible: "none" };
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.displayItem = action.payload.displayItem;
    },
  },
});

export const openModal = modalSlicer.actions.openModal;
export const closeModal = modalSlicer.actions.closeModal;

export default modalSlicer.reducer;
