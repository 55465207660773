import "./Menu.css";
import * as React from "react";
import logoQuest from "../../assets/images/logoQuest.svg";
import Menu from "@mui/material/Menu";
import { Button } from "@mui/material";

export default function MenuComponent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Change dashboard
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="menu">
          <div className="menu-items">
            <a href="/#/onboarding">Profile</a>
            <a href="/#/dashboard">Questee</a>
            <a href="/#/linemanager/dashboard">Line manager</a>
            <a href="/#/host/approval">Host</a>
            <a href={process.env.REACT_APP_POWERAPPS_URL} target="_blank">Lovinklaan</a>
          </div>
        </div>
      </Menu>
    </div>
  );
}
