export const TOKEN_KEY = "SMT";
export const TOKEN_USER_DATA = "UDS";

export const loginUser = (token, userObject) => {
  localStorage.clear();
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_USER_DATA, JSON.stringify(userObject));
};

export const setUserInformation = (data) => {
  localStorage.setItem(TOKEN_USER_DATA, JSON.stringify(data));
};

export const logoutUser = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_USER_DATA);
};

export const getUserToken = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  return token;
};

export const getUser = () => {
  let user = localStorage.getItem(TOKEN_USER_DATA);
  if (user !== {} && user !== "" && user !== null) {
    return user;
  } else {
    return null;
  }
};
