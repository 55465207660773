export default function PricavyText() {
  return (
    <>
      <h5>How do we ensure your data is protected and what are your rights?</h5>
      Privacy Notice – whether you’re a client, supplier or business partner
      this Privacy Notice explains how and why we collect and use your personal
      data. It also outlines your rights on how to obtain, review or withdraw
      your data. We may change this Privacy Notice from time to time. Any such
      changes will be posted here, and any material changes will be made
      prominent. We encourage you to review this page from time to time.
      <p>
        Contact Us: For privacy related queries incl. rectification requests,
        access requests and complaints please contact privacy@arcadis.com. For
        all other queries please click here.
      </p>
      INTERNAL NOTES, TO BE DELETED FROM PUBLISHED VERSION · THIS NOTICE IS USED
      FOR THE TRANSPARENCY REQUIREMENT UNDER THE GDPR AND PRIVACY STANDARD FOR
      CONTROLLER (CLIENT, SUPPLIER AND BUSINESS PARTNER DATA) · FOR MORE
      INFORMATION, VISIT THE MANAGEMENT SYSTEM FOR PRIVACY · THIS NOTICE IS USED
      TO INFORM BUSINESS CONTACTS OF ARCADIS (SUCH AS CONTACT PERSONS WITHIN
      CORPORATE CLIENTS, SUPPLIERS AND BUSINESS PARTNERS OR OTHER BUSINESS
      INDIVIUDALS ARCADIS DEALS WITH) OF THE PROCESSING OF THEIR PERSONAL DATA ·
      THIS IS A GLOBAL TEMPLATE, PLEASE MAKE ONLY THOSE ADJUSTMENTS AS REQUIRED
      UNDER YOUR MANDATORY LOCAL LAW OR BASED ON YOUR LOCAL PRACTICES · PLEASE
      PUBLISH ON ALL YOUR CLIENT FACING WEBSITES
      <h5>Business Contact Privacy Notice</h5>
      <h5>1. General</h5>
      This Privacy Notice is applicable to the processing of all personal data
      of Business Contacts of Arcadis N.V. and its group companies (together
      ‘Arcadis’). ‘Business Contact’ or ‘you’ means each individual whose
      personal data is processed by Arcadis in its role as controller. This is
      when you work at a client, supplier or business partner of Arcadis, when
      you are a recipient of commercial messages of Arcadis or when you have
      another business relationship with Arcadis. This also includes contingent
      workers, people working at Arcadis as consultants or employees of third
      parties providing services to Arcadis. The following people are referred
      to the Arcadis Employee Privacy Notice: Employees of Arcadis, job
      applicants or former employees of Arcadis, temporary workers who are
      individuals engaged by Arcadis through a third-party agency whereby the
      third-party agency has the responsibility for salary, tax and social
      security obligations of the temporary worker (excluding contingent
      workers), and (former) executive or non-executive directors of Arcadis or
      (former) members of the supervisory board or similar body to Arcadis. The
      local Arcadis entity you are dealing with and Arcadis N.V. are the
      controllers of your personal data as described in this Privacy Notice. The
      contact details of your local Arcadis entity and Arcadis N.V. can be found
      at the Arcadis website.
      <h5>2. How do we collect personal data?</h5>
      We collect your personal data both online and offline. Most commonly, we
      receive your personal data: · From your direct interaction with us, either
      when doing business with you or when you visit our events or website. ·
      From your employer when your employer is our client, supplier or business
      partner, so we can perform our services and work with you. · From public
      data sources like sanction lists, trade registers and LinkedIn, for
      instance where we need such personal data to verify your identity before
      we do business with you.
      <h5>3. Purposes for which Arcadis processes your personal data</h5>
      Arcadis processes your personal data for the purposes as set out below. We
      also inform you of the legal ground on the basis of which we process your
      personal data (as required by the EU General Data Protection Regulation).
      Where we rely on legitimate interest as a legal ground, we will always
      seek to maintain a balance between our legitimate business interests as
      described below and your privacy. If Arcadis processes personal data for
      other purposes than as listed in this Privacy Notice, you will be informed
      thereof separately where required and consent will be sought if applicable
      local law so requires. 3.1. Assessment and acceptance of clients,
      conclusion and execution of agreements with clients, suppliers and
      business partners. This purpose includes processing of personal data that
      is necessary in connection with the assessment and acceptance of clients,
      suppliers and business partners, including confirming and verifying the
      identity of relevant Business Contacts (this may involve the use of a
      credit reference agency or other third parties) and conducting due
      diligence and screening against publicly available government and/or law
      enforcement agency sanctions lists (e.g. for compliance requirements).
      This purpose also includes the processing of personal data necessary to
      conclude and execute agreements with clients, suppliers and business
      partners, including required screening activities (e.g. for access to
      Arcadis’ premises or systems), delivery of client services, and to record
      and financially settle delivered services and projects to and from
      Arcadis. · The legal grounds for these processing activities are: - the
      fact that the processing is necessary for the performance of a contract to
      which you are a party; - compliance with a legal obligation to which
      Arcadis is subject; or - our legitimate interest. 3.2. Development and
      improvement of Arcadis’ services. This purpose addresses processing of
      personal data that is necessary for the development and improvement of
      Arcadis services and for research and development. This includes
      processing of business contact personal data for surveys, reviews and
      research. · The legal ground for these processing activities is our
      legitimate interest. 3.3. Relationship management and marketing. This
      purpose includes processing of personal data that is necessary for
      activities such as maintaining and promoting contact with clients,
      suppliers and business partners via marketing communications, account
      management, client services, execution and analysis of market surveys and
      marketing strategies. · The legal grounds for these processing activities
      are: - your consent; - the fact that the processing is necessary for the
      performance of a contract to which you are a party; or - our legitimate
      interest. 3.4. Business process execution, internal management and
      management reporting. This purpose includes processing of personal data
      that is necessary for the management of company assets, conducting audits
      and investigations, reviewing and monitoring compliance with internal
      policies and procedures, finance and accounting, implementing business
      controls, providing central processing facilities for efficiency purposes
      and managing mergers, acquisitions and divestitures. This purpose also
      includes processing personal data for management reporting and analysis,
      archiving and insurance, legal or business consulting and preventing,
      preparing for or engaging in dispute resolution. · The legal grounds for
      these processing activities are: - the fact that the processing is
      necessary for the performance of a contract to which you are a party; -
      compliance with a legal obligation to which Arcadis is subject; or - our
      legitimate interest. 3.5. Health, safety, security and integrity purposes.
      This purpose includes the processing of personal data that is necessary
      for the protection of the rights, interests and assets of Arcadis and its
      employees, clients, suppliers and business partners and activities such as
      those involving health and safety. It also includes the authentication of
      client, supplier or business partner status and access rights. · The legal
      grounds for these processing activities are: - the fact that the
      processing is necessary for the performance of a contract to which you are
      a party; - compliance with a legal obligation to which Arcadis is subject;
      or - our legitimate interest. 3.6. Compliance with the law. This purpose
      includes the processing of personal data that is necessary for the
      performance of a task carried out to comply with a legal obligation to
      which Arcadis is subject, including the disclosure of personal data to
      government institutions or supervisory authorities. · The legal ground for
      these processing activities is compliance with a legal obligation to which
      Arcadis is subject. 3.7. Protection of the vital interests of Business
      Contacts. This purpose includes the processing of personal data that is
      necessary to protect the vital interests of you as our Business Contact. ·
      The legal ground for these processing activities is protection of the
      vital interests of an individual.
      <h5>4. Categories of personal data</h5>
      The below overview contains the categories of personal data processed by
      Arcadis for the purposes described in this Privacy Notice. If Arcadis
      processes other categories of personal data than as listed in this Privacy
      Notice, you will be informed separately thereof where required and consent
      will be sought if applicable local law so requires. 4.1. Your personal
      details: including work e-mail address, work telephone number and work
      address; private email address, private telephone number, home address;
      job function or position; job history; picture or video. 4.2. Your company
      and communication details: correspondence between you and Arcadis;
      information to check your identity; your relationship with Arcadis; your
      behaviour towards Arcadis, its employees or clients, suppliers and
      business partners; credibility of your business; online available
      information (information on the processing of personal data that is
      collected via cookies or similar technologies, can be found in the cookie
      statements on the Arcadis websites).
      <h5>5. Sensitive personal data</h5>
      In the course of your business relationship with Arcadis, Arcadis may need
      to collect certain data viewed as ‘sensitive’ because they reveal intimate
      characteristics, such as religion, ethnicity, criminal acts or health.
      Such sensitive data shall only be used within the strict limits set out by
      applicable local law. Such sensitive data processing activities conducted
      by Arcadis may, in accordance with applicable local requirements, include
      the following: 5.1. Your image may be processed by Arcadis in as far as
      necessary for identification or future reference purposes, for site access
      and security reasons and for the identification and authentication of
      clients, suppliers or business partners. 5.2. Data relating to criminal
      behaviour, criminal records or proceedings regarding criminal or unlawful
      behaviour may be processed by Arcadis in as far as necessary for
      assessment and acceptance of clients, suppliers or business partners, for
      the protection of the rights, interests and assets of Arcadis, its
      employees and clients, suppliers and business partners and to comply with
      applicable legal obligations. 5.3. Physical or mental health data may be
      processed by Arcadis in as far as necessary for the assessment and
      acceptance of a client, supplier or business partner, the execution of an
      agreement with a client, supplier and business partner, compliance with
      Arcadis’ duty of care towards clients, suppliers and business partners and
      for the protection of your vital interests. 5.4. Personal data on religion
      or beliefs may be processed by Arcadis in as far as necessary to
      accommodate specific services for a client, including dietary requirements
      or religious holidays. Where required, your explicit consent will be
      sought before the processing of sensitive personal data takes place. Other
      legal grounds on the basis of which Arcadis may process the sensitive
      personal data as described above are: - the legitimate interest of Arcadis
      to protect its rights, interests and assets and the rights, interests and
      assets of its employees, clients, suppliers and business partners; - the
      legitimate interest of Arcadis to ascertain your identity and status; -
      compliance with a legal obligation of which Arcadis is subject;
      <h5>6. For what period does Arcadis retain your personal data?</h5>
      In general, Arcadis will retain your personal data for the duration of
      your business relationship with Arcadis (e.g. during the time Arcadis
      services are delivered to your employer and you are our Business Contact)
      and for the time it is necessary to keep your personal data after the end
      of the services. This period will depend on our purpose of use of your
      personal data. Examples are: · Where your business contact details are
      included in our invoices, we will keep your personal data together with
      our invoices (usually 7 years based on tax legislation). · Where your
      business contact details are included in our project plans, we will retain
      your personal data in accordance with our project plan retention periods
      (usually 10 years depending on the project). · Where we need your personal
      data for identification and screening purposes, we will keep your personal
      data for as long as we are required to keep proof of the screening based
      on local law. · If you are one of our prospects, we will keep your contact
      details during the time that Arcadis sends you commercial messages you are
      interested in (such as newsletters) and 24 months thereafter. Arcadis will
      deviate from these retention periods if Arcadis has a pressing interest to
      keep your personal data longer (e.g. in case of ongoing or expected
      litigation).
      <h5>7. Personal data access and transfer</h5>
      7.1. Access to your personal data is only authorized to the extent such
      access is necessary to serve the intended purpose and for the respective
      staff to perform their job. Staff that are authorized to access your
      personal data may include your Arcadis point of contact, and Arcadis
      personnel such as within Finance, Reporting, Internal Audit, IT, marketing
      and Legal. 7.2. From time to time, Arcadis may need to make personal data
      available to unaffiliated third parties, such as service providers
      (companies that provide products and services to Arcadis such as payment
      providers, IT systems suppliers), professional advisors (such as
      accountants, auditors, or lawyers), public and governmental authorities
      (entities that regulate or have jurisdiction over Arcadis such as
      regulatory authorities, law enforcement, public bodies and judicial
      bodies), or in the context of corporate transactions (in connection with
      any proposed or actual reorganization, merger or sale). Arcadis will put
      in place agreements with third party service providers and professional
      advisors to protect your data protection interests. 7.3. Due to the global
      nature of Arcadis’ operations, Arcadis may need to disclose personal data
      to its own personnel and departments in other countries. Some of the
      unaffiliated third parties may also be located outside of your home
      jurisdiction. Where such international data transfer takes place to a
      country that has a different data protection regime, Arcadis will ensure
      that the international data transfer will not negatively affect the level
      of protection of your personal data. Where required, Arcadis will inform
      you of any additional details on the international data transfer. 7.4. In
      case the recipient of the personal data is based outside the European
      Economic Area to a country that is not considered to have a level of data
      protection adequate to the EU, Arcadis will ensure that this transfer is
      based on appropriate safeguards including EU Model Clauses or Binding
      Corporate Rules. Information on such safeguards can be obtained via the
      Arcadis Privacy Officers at arcadis@privacy.com.
      <h5>8. Security</h5>
      8.1. Arcadis will take appropriate technical, physical and organizational
      measures to protect personal data from misuse or accidental, unlawful, or
      unauthorized destruction, loss, alteration, disclosure acquisition or
      access, that are consistent with applicable privacy and data security laws
      and regulations. This will include requiring service providers to use
      appropriate measures to protect the confidentiality and security of
      personal data.
      <h5>9. Individual rights and complaints</h5>
      9.1. Where your personal data are incorrect or incomplete, you always have
      the right to have your personal data rectified or completed by Arcadis.
      9.2. Certain local laws grant you the right of access and deletion of
      personal data, objection to processing of personal data, restriction of
      processing of personal data, the right to withdraw consent and the right
      of data portability. Contact your local Privacy Officer to asses if you
      have these rights based on local laws or regulations.
      <h6>What are these rights?</h6>
      9.3. The right of access means that you have the right to request an
      overview of your personal data processed by or on behalf of Arcadis. The
      rights of deletion and restriction imply that if your personal data is not
      processed in compliance with applicable law, you have the right to have
      this personal data deleted or restricted (as appropriate). The right to
      object means you can object to processing of your personal data (as
      appropriate). 9.4. The right of data portability means that a copy of your
      personal data provided by you to Arcadis will, at your request, be
      transferred to you in a common machine-readable format or transmitted to a
      third party without hindrance. 9.5. The right of withdrawal means that if
      the processing of your personal data was based on consent, you have the
      right to withdraw such consent. Such withdrawal will not affect the
      lawfulness of the processing based on consent before the withdrawal. You
      can manage your subscription settings via our subscription website. 9.6.
      You can exercise your rights or file a complaint by contacting the Privacy
      Officer at privacy@arcadis.com. Arcadis will perform your request in
      accordance with local law as applicable to you. 9.7. For data protection
      or privacy related complaints, you also have the right to file a complaint
      with the applicable supervisory authority.
    </>
  );
}
