import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './HelpComponent.css';

export default function HelpComponent(){
  return (
    <div className="help-text">
      <p>If you experience any issues with your form, <br/> please, use the field below.</p>
      <p><a href="mailto:thequestprogram@arcadis.com?subject=Help with QUEST" style={{ color: 'purple' }}>Ok, I need help <HelpOutlineIcon /></a></p>
    </div>
  )
}
