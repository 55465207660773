import "./HostCard.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import HostApprovalDrawer from "../HostApprovalDrawer";
import QuestTypeTag from "../QuestTypeTag/indx";
import StatusTag from "../StatusTag";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function HostCard({ quest, isLovinklaan, updateData }) {

  return (
    <div className="host-card">
      <div className="host-card-header">
        <div className="header-first-tags">
          <QuestTypeTag maxQt={quest.maxQty} />
          <h2 style={{ marginRight: "8px" }}>
            <LocationOnIcon fontSize="18px" />
            {quest.country}
          </h2>
          <h2>
            <CalendarMonthIcon style={{ fontSize: "16px" }} />
            {quest.date}
          </h2>
        </div>
        <StatusTag status={quest.status} />
      </div>
      <h1>
        {quest.type == "Single Quest"
          ? quest.questTitle
          : quest.opportunityInformation.find((el) => el.question == "title")
              ?.answer}
      </h1>
      <h3>
        {quest.type == "Single Quest"
          ? quest.opportunityInformation.find((el) => el.question == "value_1")
              ?.answer
          : quest.opportunityInformation.find((el) => el.question == "details")
              ?.answer}
      </h3>
      <div className="host-card-bottom">
        <div style={{ visibility: !isLovinklaan ? "visible" : "hidden" }}>
          <AvatarGroup>
            {/* src={`data:image/jpeg;base64,${questee.questeePicture}`} */}
            {quest.questes.map((el, index) => {
              if (index < 3) {
                return (
                  <Avatar
                    // index={index}
                    key={index}
                    sx={{ width: 24, height: 24 }}
                    alt=""
                    src={`data:image/jpeg;base64,${el?.questeePicture}`}
                  />
                );
              }
            })}
          </AvatarGroup>
        </div>

        {quest.status !== "On hold" && (
          <HostApprovalDrawer
            questInfo={quest}
            isLovinklaan={isLovinklaan}
            questees={quest.questes}
            type={quest.type}
            updateData={(status, questId) => updateData(status, questId)}
            status = {quest.status}
          />
        )}
      </div>
    </div>
  );
}
