import "./SignInButton.css";

import React, { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig.js";
import { loginUser } from "../../services/Authentication/userAuthentication.js";
import { useNavigate } from "react-router-dom";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  isApplicationHandler,
  msTokenHandler,
  setAuthHandler,
  signIn,
} from "../../store/redux/authSlice.js";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";

import { useDispatch, useSelector } from "react-redux";
import { storeUserData } from "../../store/redux/authSlice.js";
import { fillQuesteeDataHandler } from "../../store/redux/questeeSlice.js";

import Button from "react-bootstrap/Button";
import Api from "../../services/Api.jsx";
/**
 * Renders a button which, when selected, will open a popup for login
 */
export default function SignInButton(props) {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const userData = useSelector(
    (state) => state?.authPersistReducer?.userData
  );
  const pathLocation = useSelector(
    (state) => state.authPersistReducer.pathLocation
  );

  useEffect(() => {
    dispatch(
      setAuthHandler({
        auth: true,
      })
    );
  }, []);

  const fillQuesteeData = (userData) => {

    if (userData.Quests.length) {
      dispatch(
        fillQuesteeDataHandler({
          questId: userData.Quests[0].questId,
          type: userData.Quests[0].type,
          notFlexibleDate: userData.Quests[0].reasonForDate,
          country: userData.Quests[0].country,
          office: userData.Quests[0].office,
          hostEmail: userData.host?.User?.email,
          month: userData.Quests[0].month,
          year: userData.Quests[0].year,
          flexibleDate: userData.Quests[0].dateFlexible,
          holidaysConflicts: userData.Quests[0].holidaysConflict,
          holidayHasConflict: userData.Quests[0].reasonForHoliday,
          objective1: userData.questions[0].answer,
          objective1Description: userData.questions[1].answer,
          objective2: userData.questions[2].answer,
          objective2Description: userData.questions[3].answer,
          objective3: userData.questions[4].answer,
          objective3Description: userData.questions[5].answer,
          value1: userData.questions[6].answer,
          value2: userData.questions[7].answer,
          value3: userData.questions[8].answer,
          submited: userData.Quests[0].currentStep > 1,
          status: userData.Quests[0].QuesteeInfos[0].status,
          questStep: userData.Quests[0].QuesteeInfos[0].questStep,
        })
      );
    }
  };

  const navigate = useNavigate();
  const Accounts = instance.getAllAccounts();
  let accountIdentifier = {
    localAccountId: "",
    homeAccountId: "",
  };
  if (Accounts.length > 0) {
    accountIdentifier = {
      localAccountId: Accounts[0].localAccountId,
      homeAccountId: Accounts[0].homeAccountId,
    };
  }
  const isAuthenticated = useIsAuthenticated(accountIdentifier);

  async function handleAuthorization(response, loginType) {
    dispatch(
      msTokenHandler({
        msToken: response.accessToken,
      })
    );
    try {
      const AuthStatus = await Api.post("user/authenticate", null, {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      });
      if (AuthStatus.data.status === true) {
        sessionStorage.setItem("loginPage", true);
        dispatch(
          signIn({
            userRole: AuthStatus.data.user.Roles.map((role) => role.roleId),
            name: response.account.name.split(",")[1],
            msToken: response.accessToken,
            token: AuthStatus.data.token,
            isEligible: AuthStatus.data.isEligible,
            questeeStatus: AuthStatus.data.user.questeeStatus,
          })
        );
        loginUser(AuthStatus.data.token, AuthStatus.data.user);

        dispatch(storeUserData({ data: AuthStatus.data.user }));

        fillQuesteeData(AuthStatus.data.user);
        dispatch(untoggleLoadingHandler());

        if (userData&& userData.Quests && userData.Quests.length > 0) {
          fillQuesteeData(AuthStatus.data.user);
        }



        let redirect = pathLocation !== "" && props.title !== "APPLY NOW" ? pathLocation : "/quests/dashboard";

        if (loginType === "popupApp") {
          dispatch(isApplicationHandler({ status: true }));
        }
        return navigate(redirect);
      } else {
        dispatch(untoggleLoadingHandler());

        return navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
      dispatch(untoggleLoadingHandler());

      return;
    }
  }
  const handleLogin = async (loginType) => {
    dispatch(toggleLoadingHandler());

    if (isAuthenticated === true) {
      const tokenRequest = {
        account: Accounts[0], // This is an example - Select account based on your app's requirements
        scopes: ["User.Read"],
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          // Call your API with the access token and return the data you need to save in state
          return handleAuthorization(response, loginType);
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            await instance.loginPopup(loginRequest).then(async (response) => {
              dispatch(toggleLoadingHandler());
              return handleAuthorization(response, loginType);
            });
          }
          throw e;
        });
    }
    if (loginType === "popup" || loginType === "popupApp") {
      await instance
        .loginPopup(loginRequest)
        .then(async (response) => {
          // dispatch(toggleLoadingHandler());

          return handleAuthorization(response, loginType);
        })
        .catch((error) => {
          console.log(error);
          // dispatch(untoggleLoadingHandler());
          return;
        });
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        className={
          props.title === "LOGIN" || props.title === "APPLY NOW"
            ? "login-button-landing"
            : "signIn-button"
        }
        onClick={() => handleLogin("popup")}
        id={props.title === "APPLY NOW" ? "bttn" : ""}
      >
        {props.title}
      </Button>
    </>
  );
}
