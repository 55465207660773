import "./OnboardingPage.css";

import { Select } from "@mui/material";
import { useEffect, useState } from "react";
import { arcadisCountries } from "../../assets/countries/arcadisCountries";
import { useNavigate } from "react-router-dom";
import { setUserInformation } from "../../services/Authentication/userAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  isApplicationHandler,
  storeUserData,
} from "../../store/redux/authSlice";
import { fillDataDynamically } from "../../store/redux/questeeSlice";
import { openModal } from "../../store/redux/modalSlicer";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer";

import Header from "../../components/Header";
import Api from "../../services/Api";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import HelpComponent from "../../components/HelpComponent";
import PeoplePicker from "../../components/PeoplePicker";

export default function OnboardingPage() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.authPersistReducer.userData);

  const [formData, setFormData] = useState({
    name: userData?.name,
    lastName: userData?.lastName,
    email: userData?.email,
    jobTitle: userData?.jobTitle ? userData?.jobTitle : "",
    country: userData?.country ? userData?.country : "",
    office: userData?.office ? userData?.office : "",
    gba: userData?.gba ? userData?.gba : "",
    solution: userData?.solution ? userData?.solution : "",
    gbaOfficer: userData?.gbaOfficer ? userData?.gbaOfficer : "",
    baDirector: userData?.baDirector ? userData?.baDirector : "",
    arcadisYears: userData?.arcadisYears ? userData?.arcadisYears : "",
    workingYears: userData?.workingYears ? userData?.workingYears : "",
    manager: userData?.Manager ? userData?.Manager.email : "",
  });
  const [otherGba, setOtherGba] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const { questIdToApply, type, questApplication } = useSelector(
    (state) => state.questeePersistReducer
  );

  const [gbas, setGbas] = useState([]);
  const [gbaSolutions, setGbaSolutions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    Api.get("/gba/index").then((response) => {
      response.data.map((gba) => gba.gbaName);
      setGbas([...new Set(response.data.map((gba) => gba.gbaName))]);
      setGbaSolutions(response.data);
    });
  }, []);

  async function applyQuest() {
    dispatch(toggleLoadingHandler());

    let profile = await fillProfile();
    let typeCondition = type;
    typeCondition = 1;
    if (profile) {
      if (typeCondition == 1) {
        dispatch(
          fillDataDynamically({
            value: 0,
            target: "formIndex",
          })
        );
      } else {
        dispatch(
          fillDataDynamically({
            value: 1,
            target: "formIndex",
          })
        );
        Api.post("/quest/questeeApplication", {
          questId: questIdToApply,
          userId: userData.userId,
          questions: [
            {
              question: "objective_type_1",
              answer: "",
              label: "Objective 1",
            },
            {
              question: "objective_1",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 1",
            },
            {
              question: "objective_type_2",
              answer: "",
              label: "Objective 2",
            },
            {
              question: "objective_2",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 2",
            },
            {
              question: "objective_type_3",
              answer: "",
              label: "Objective 3",
            },
            {
              question: "objective_3",
              answer: "",
              label: "Explanation of Skill/Knowledge Need for Objective 3",
            },
            {
              question: "value_1",
              answer: "",
              label:
                "Describe how your Quest ties to the mission, vision, and values of Arcadis",
            },
            {
              question: "value_2",
              answer: "",
              label:
                "Indentify areas of expertise and knowledge that the Host and Questee(s), will share with each other",
            },
            {
              question: "value_3",
              answer: "",
              label:
                "Explain how participating in the Quest will support Host/Questee career development",
            },
          ],
        }).catch((e) => {
          console.log(e);
        });

        dispatch(
          storeUserData({
            ...userData,
            questeeStatus: "P",
          })
        );
        dispatch(
          fillDataDynamically({
            value: false,
            target: "questApplication",
          })
        );
        dispatch(untoggleLoadingHandler());
      }
      return navigate("/quest/application");
    }
  }

  async function fillProfile() {
    dispatch(toggleLoadingHandler());

    try {
      let HasInvalidItems = [];
      let ToBeVerified = Object.values(formData);

      ToBeVerified.map((item) => {
        if (item === "" || item === null || item === undefined) {
          return HasInvalidItems.push(true);
        } else {
          return HasInvalidItems.push(false);
        }
      });
      if (HasInvalidItems.includes(true) === true) {
        dispatch(untoggleLoadingHandler());

        dispatch(
          openModal({
            title: "Error",
            displayItem: "You need to fill in all information to continue.",
          })
        );
        return;
      }
      let response = await Api.post("/user/create", {
        formData,
      });
      if (response.data.status === true) {
        const newUser = await Api.post("/user/index", {
          email: userData?.email,
        });
        setUserInformation({ ...newUser.data });
        dispatch(
          storeUserData({
            ...newUser.data,
            newUser: false,
            onboardingFinished: true,
          })
        );
        dispatch(untoggleLoadingHandler());

        dispatch(isApplicationHandler({ status: false }));
      }

      return true;
    } catch (err) {
      console.log(err);
      dispatch(untoggleLoadingHandler());
      return false;
    }
  }

  const handleGbaChange = (e) => {
    setFormData({
      ...formData,
      gba: e.target.value,
      gbaOfficer:gbaSolutions.find((gba) => gba.gbaName === e.target.value)?.gbaResponsible
    });

    if (e.target.value === "Other") {
      setOtherGba(true);
    } else {
      setOtherGba(false);
    }
  };

  const setFunction = async () => {
    if (!questApplication) {
      return fillProfile();
    } else {
      return applyQuest();
    }
  };

  return (
    <>
      <Header />
      <div className="onboarding-page">
        <div className="onboarding-message-holder">
          <h1 className="onboarding-message-title">Hello, {userData?.name}!</h1>
          <h5 className="onboarding-message-subtitle">
            Before we continue, let's complete your profile
          </h5>

          <HelpComponent />
        </div>
        <div className="oboarding-form">
          <div className="double-input">
            <div className="input-div-double">
              <span className="input-label">
                First Name<span className="mandatory">*</span>
              </span>
              <input
                value={userData?.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="single-input"
                disabled
              />
            </div>
            <div className="input-div-double">
              <span className="input-label">
                Last Name<span className="mandatory">*</span>
              </span>
              <input
                value={userData?.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                // defaultValue={formData.lastName}
                className="single-input"
                disabled
              />
            </div>
          </div>
          <div className="input-div">
            <span className="input-label">
              Email<span className="mandatory">*</span>
            </span>
            <input value={formData.email} className="large-input" disabled />
            <span className="info-label">
              You can't edit your e-mail account since it's linked to your
              Arcadis account.
            </span>
          </div>
          <div className="double-input">
            <div className="input-div-double">
              <span className="input-label">
                Years at Arcadis<span className="mandatory">*</span>
              </span>
              <Select
                className="dropdown"
                onChange={(e) =>
                  setFormData({ ...formData, arcadisYears: e.target.value })
                }
                defaultValue={formData.arcadisYears}
              >
                <MenuItem value={"<1"}>Less than one year</MenuItem>
                <MenuItem value={"1~5"}>1 ~ 5 years</MenuItem>
                <MenuItem value={"6~10"}>6 ~ 10 years</MenuItem>
                <MenuItem value={"11~15"}>11 ~ 15 years</MenuItem>
                <MenuItem value={"16~20"}>16 ~ 20 years</MenuItem>
                <MenuItem value={"21~25"}>21 ~ 25 years</MenuItem>
                <MenuItem value={"25+"}>25+ years</MenuItem>
              </Select>
            </div>
            <div className="input-div-double">
              <span
                className="input-label"
                style={{
                  width: "230px",
                  display: "inline-flex",
                  fontSize: "14px",
                }}
              >
                Years of Working Experience <span className="mandatory">*</span>
              </span>
              <Select
                className="dropdown"
                onChange={(e) =>
                  setFormData({ ...formData, workingYears: e.target.value })
                }
                defaultValue={formData.workingYears}
              >
                <MenuItem value={"<1"}>Less than one year</MenuItem>
                <MenuItem value={"2~5"}>2 ~ 5 years</MenuItem>
                <MenuItem value={"5~10"}>5 ~ 10 years</MenuItem>
                <MenuItem value={"10~15"}>10 ~ 15 years</MenuItem>
                <MenuItem value={"15 ~ 20"}>15 ~ 20 years</MenuItem>
                <MenuItem value={"25+"}>25+ years</MenuItem>
              </Select>
            </div>
          </div>
          {formData.arcadisYears === "<1" && (
            <Alert className="warning" severity="warning">
              <span className="warning-title">
                To apply for a Quest, you must have been with Arcadis for a
                minimum of one year.
              </span>
              <br></br>
              <span className="warning-message">
                We look forward to seeing your application in the future!
              </span>
            </Alert>
          )}
          <div className="input-div">
            <span className="input-label">
              Your role / Job Title<span className="mandatory">*</span>
            </span>
            <input
              placeholder="Add your role / job title"
              onChange={(e) =>
                setFormData({ ...formData, jobTitle: e.target.value })
              }
              className="large-input"
              defaultValue={formData.jobTitle}
            />
          </div>
          <div className="double-input">
            <div className="input-div-double">
              <span className="input-label">
                Your Home Country <span className="mandatory">*</span>
              </span>
              <Select
                className="dropdown"
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
                defaultValue={formData.country}
              >
                <MenuItem value="" selected={true}>
                  Select your country
                </MenuItem>
                {arcadisCountries.map((country, index) => (
                  <MenuItem value={country.name} key={index}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="input-div-double">
              <span className="input-label">
                Your Home Office<span className="mandatory">*</span>
              </span>
              <input
                placeholder="Inform your office"
                onChange={(e) =>
                  setFormData({ ...formData, office: e.target.value })
                }
                className="single-input"
                defaultValue={formData.office}
              />
            </div>
          </div>
          <div className="input-div">
            <span className="input-label">Your Global Business Area*</span>
            <Select
              className="dropdown"
              style={{ width: "100%" }}
              onChange={(e) => handleGbaChange(e)}
              defaultValue={formData.gba}
            >
              {gbas.map((gbaName, index) => (
              <MenuItem value={gbaName} key={index}>
                {gbaName}
              </MenuItem>
              ))}
            </Select>
          </div>
          {otherGba && (
            <div className="input-div">
              <span className="input-label">
                Specify your Business Area<span className="mandatory">*</span>
              </span>
              <input
                onChange={(e) =>
                  setFormData({ ...formData, gba: e.target.value })
                }
                className="large-input"
                defaultValue={formData.gba}
              />
            </div>
          )}
          <div className="input-div">
            <span className="input-label" style={{ width: "300px" }}>
              GBA Officer<span className="mandatory">*</span>
            </span>
          </div>
          <div className="large-input">
            <input
              value={
                gbaSolutions.find((gba) => gba.gbaName === formData.gba)
                  ?.gbaResponsible
              }
              className="large-input"
              onChange={(e) =>
                setFormData({ ...formData, gbaOfficer: e.target.value })
              }
              placeholder="gba.officer@arcadis.com"
              disabled
            />
          </div>
          <div className="input-div">
            <span className="input-label" style={{ width: "300px" }}>
              Which solution or service are you aligned with?
              <span className="mandatory">*</span>
            </span>
            <Select
              className="dropdown"
              style={{ width: "100%" }}
              onChange={(e) =>
                setFormData({ ...formData, solution: e.target.value })
              }
              defaultValue={formData.solution}
            >
              {gbaSolutions.filter(gbaSolution=>gbaSolution.gbaName == formData.gba).map((gbaName, index) => (
              <MenuItem value={gbaName.solutionName} key={index}>
                {gbaName.solutionName}
              </MenuItem>
              ))}
            </Select>
          
          </div>
          <div className="input-div">
            <span className="input-label" style={{ width: "300px" }}>
              Home Supervisor / Line Manager<span className="mandatory">*</span>
            </span>
            <PeoplePicker
              defaultValue={formData.manager}
              onChangeFunction={(e) => {
                return setFormData({
                  ...formData,
                  manager: e,
                });
              }}
            />
          </div>
          <div className="input-div">
            <span className="input-label" style={{ width: "300px" }}>
              BA Director<span className="mandatory">*</span>
            </span>
            <PeoplePicker
              defaultValue={formData.baDirector}
              onChangeFunction={(e) => {
                return setFormData({
                  ...formData,
                  baDirector: e,
                });
              }}
            />

            <span className="info-label">
              If you are not assigned to a Global Business Area, please provide
              the email address of the supervisor two levels up in your
              reporting structure.
            </span>
          </div>

          <div className="button-div">
            <button
              disabled={formData.arcadisYears === "<1"}
              style={formData.arcadisYears === "<1" ? { opacity: "0.2" } : {}}
              id="function-button"
              onClick={setFunction}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
