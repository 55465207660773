import "./Dashboard.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileComponent from "../../components/Dashboard/ProfileComponent";
import DashboardComponent from "../../components/Dashboard/DashBoardComponent";
import Loader from "../../components/Loader";
import Header from "../../components/Header";

export default function Dashboard() {
  const [questId, setQuestId] = useState([]);
  const userData = useSelector((state) => state.authPersistReducer.userData);
  const {
    country,
    office,
    hostEmail,
    objective1,
    objective1Description,
    objective2,
    objective2Description,
    objective3,
    objective3Description,
    value1,
    value2,
    value3,
  } = useSelector((state) => state.questeePersistReducer);

  useEffect(() => {
    if (userData?.Quests?.length) {
      setQuestId(userData.Quests[0].questId);
    }
  }, []);

  return (
    <>
      <Header />
        {userData?.length === 0 && (
          <div
            style={{ marginLeft: "100px", height: "800px", marginTop: "100px" }}
          >
            <Loader type={"spinningBubbles"} color={"#ffffff"} />
          </div>
        )}

        {userData?.length !== 0 && userData.questeeBefore === true && (
          <div className="test-div">
            <div className="dashboard-page-wrapper">
              <DashboardComponent
                isUser={true}
                userInfo={userData}
                questStatus={"not submited"}
              />
              <ProfileComponent />
            </div>
          </div>
        )}
        {userData?.length !== 0 && userData.questeeBefore === false && (
          <div >
            <div className="dashboard-page">
              <DashboardComponent
                name={userData["name"]}
                country={country}
                office={office}
                hostEmail={hostEmail}
                isUser={true}
                answer0={objective1}
                answer1={objective1Description}
                answer2={objective2}
                answer3={objective2Description}
                answer4={objective3}
                answer5={objective3Description}
                answer6={value1}
                answer7={value2}
                answer8={value3}
                userInfo={userData}
                // questStatus={
                //   userData.Quests.length === 0 ? "not submited" : "submited"
                // }
                introText={"Welcome to your Quest application dashboard!"}
              />
              {userData?.length !== 0 ? (
                <ProfileComponent
                  name={userData.name ? userData.name : ""}
                  jobTitle={userData.jobTitle ? userData.jobTitle : ""}
                  country={userData.country ? userData.country : ""}
                  office={userData.office ? userData.office : ""}
                  managerEmail={
                    userData.Manager?.email ? userData.Manager.email : ""
                  }
                  lastName={userData.lastName ? userData.lastName : ""}
                />
              ) : (
                <ProfileComponent />
              )}
            </div>
          </div>
        )}
    </>
  );
}
