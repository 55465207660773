import noQuest2 from "../../assets/images/noQuest2.svg";
import "./NoQuestsToReviewMessage.css";

export default function NoQuestsToReviewMessage(props) {
  return (
    <div className="no-quests-div" style={props.style}>
      <img src={noQuest2} alt="no quest image" className="image"></img>
      <h1 className="title-text">{props.title}</h1>
    </div>
  );
}
