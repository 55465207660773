import "./DefaultButton.css";

export default function DefaultButton(props) {
  return (
    <button
      className={props.title === "Approve" ? "approve-btn" : "reject-btn"}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
}
