import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./QuesteeTable.css";
import QuesteeTableRow from "./QuesteeTableRow";
import { useMemo } from "react";
import { useEffect } from "react";

export default function QuesteesTable(props) {

  return (
    <TableContainer component={Paper} style={{ overflow: "auto" }} sx={{width: '100%'}}>
      <Table sx={{ width: '100%', minWidth: 500}} aria-label="simple table"  className="table-div">
        <TableHead>
          <TableRow>
            <TableCell className="table-title">NAME</TableCell>
            <TableCell className="table-title">APPLICATION TYPE</TableCell>
            <TableCell className="table-title" align="left">
              HOST LOCATION
            </TableCell>
            <TableCell className="table-title" align="left">
              DATE
            </TableCell>
            <TableCell className="table-title" align="left">
              STATUS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.quests.length !== 0 && props.quests !== undefined ? (
            props.quests.map((quest) => {
              return <QuesteeTableRow type={props.type} questeeData={quest} />;
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
