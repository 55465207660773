import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
  
  name: "userSlice",

  initialState: {
    completeUserData: {},
    goToQuestions: 0,
    hasFetched: false,
  },

  reducers: {
    fillUserSliceDynamically: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
  },

});


export const fillUserSliceDynamically =
  userSlice.actions.fillUserSliceDynamically;


export default userSlice.reducer;
