import "./ObjectivesPage.css";
import { objectives } from "../../assets/objectives";
import { Alert, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routesAuthHandler } from "../../store/redux/authSlice";
import { fillDataDynamically } from "../../store/redux/questeeSlice";

import TextareaAutosize from "@mui/base/TextareaAutosize";
import Api from "../../services/Api";

export default function ObjectivesPage() {
  const dispatch = useDispatch();
  const [updateSuccess, setUpdateSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  const {
    objective1,
    objective1Description,
    objective2,
    objective2Description,
    objective3,
    objective3Description,
    questId,
    applyingToHostQuest
  } = useSelector((state) => state.questeePersistReducer);

  const { goToQuestions } = useSelector((state) => state.userReducer);
  const userData = useSelector((state) => state.authPersistReducer.userData);

  function feedQuesteeSliceData(value, target) {
    dispatch(
      fillDataDynamically({
        value: value,
        target: target,
      })
    );
  }

  const formValidation = () => {
    if (goToQuestions === 0) {
      if (
        objective1 !== "" &&
        objective1Description !== "" &&
        objective2 !== "" &&
        objective2Description !== "" &&
        objective3 !== "" &&
        objective3Description !== ""
      ) {
        feedQuesteeSliceData(false, "invalidForm");
        feedQuesteeSliceData(2, "formIndex");
      } else {
        feedQuesteeSliceData(true, "invalidForm");
      }
    } else {
      if (
        objective1 !== "" &&
        objective1Description !== "" &&
        objective2 !== "" &&
        objective2Description !== "" &&
        objective3 !== "" &&
        objective3Description !== ""
      ) {
        feedQuesteeSliceData(false, "invalidForm");
        feedQuesteeSliceData(2, "formIndex");
      } else {
        feedQuesteeSliceData(true, "invalidForm");
      }
    }
    feedQuesteeSliceData(2, "formIndex");
  };

  async function updateQuest() {
    try {
      await Api.post(`/quest/updateAll/${questId}`, {
        onlyQuestion: true,
        userId: userData.userId,
        questeeId: userData.userId,
        questions: [
          {
            question: "objective_type_1",
            answer: objective1,
          },
          {
            question: "objective_1",
            answer: objective1Description,
          },
          {
            question: "objective_type_2",
            answer: objective2,
          },
          {
            question: "objective_2",
            answer: objective2Description,
          },
          {
            question: "objective_type_3",
            answer: objective3,
          },
          {
            question: "objective_3",
            answer: objective3Description,
          },
        ],
      });
      setUpdateSuccess(true);
    } catch (e) {
      console.log(e);
    }
  
  }

  useEffect(() => {
      if (!questId) {
        dispatch(
          routesAuthHandler({
            pathLocation: "",
          })
        );
      }
  }, []);

  return (
    <div className="objective-page">
      <div className="objective-input-div">
        <div className="objective-dropdown">
          <div className="label">
            <span className="input-label">
              Objective 1<span className="mandatory">*</span>
            </span>
          </div>
          <Select
            defaultValue={objective1}
            value={objective1}
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective1");
            }}
            className="dropdown-meu"
          >
            {objectives.map((objective, index) => (
              <MenuItem
                key={index}
                disabled={
                  objective2 === objective.name || objective3 === objective.name
                }
                value={objective.name}
              >
                {objective.name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="objective-text-div">
          <div className="objective-dropdown">
            <div className="label">
              <span className="input-label" style={{ width: "100%" }}>
                Explanation of Skill/Knowledge Need for Objective 1
                <span className="mandatory">*</span>
              </span>
            </div>
          </div>
          <TextareaAutosize
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective1Description");
            }}
            defatultValue={objective1Description}
            value={objective1Description}
            minRows={4}
            className="objective-text"
          />
        </div>
      </div>

      <div className="objective-input-div">
        <div className="label">
          <span className="input-label">
            Objective 2<span className="mandatory">*</span>
          </span>
        </div>
        <div className="objective-dropdown">
          <Select
            value={objective2}
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective2");
            }}
            className="dropdown-meu"
          >
            {objectives.map((objective, index) => (
              <MenuItem
                key={index}
                disabled={
                  objective1 === objective.name || objective3 === objective.name
                }
                value={objective.name}
              >
                {objective.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="objective-text-div">
          <div className="label">
            <span className="input-label" style={{ width: "100%" }}>
              Explanation of Skill/Knowledge Need for Objective 2
              <span className="mandatory">*</span>
            </span>
          </div>
          <TextareaAutosize
            defatultValue={objective2Description}
            value={objective2Description}
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective2Description");
            }}
            minRows={4}
            className="objective-text"
          />
        </div>
      </div>
      <div className="objective-input-div">
        <div className="objective-dropdown">
          <div className="label">
            <span className="input-label">
              Objective 3<span className="mandatory">*</span>
            </span>
          </div>
          <Select
            value={objective3}
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective3");
            }}
            className="dropdown-meu"
          >
            {objectives.map((objective, index) => (
              <MenuItem
                key={index}
                disabled={
                  objective1 === objective.name || objective2 === objective.name
                }
                value={objective.name}
              >
                {objective.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="objective-text-div">
          <div className="label">
            <span className="input-label" style={{ width: "100%" }}>
              Explanation of Skill/Knowledge Need for Objective 3
              <span className="mandatory">*</span>
            </span>
          </div>
          <TextareaAutosize
            defatultValue={objective3Description}
            value={objective3Description}
            onChange={(e) => {
              feedQuesteeSliceData(e.target.value, "objective3Description");
            }}
            minRows={4}
            className="objective-text"
          />
        </div>
      </div>

      {updateSuccess === true && (
        <div className="input-div-quest-form">
          <Alert
            style={{ marginLeft: "5%", width: "90%", marginTop: "20px" }}
            className="large-input"
            severity="success"
          >
             Your application has been saved but has not been submitted.
          </Alert>
        </div>
      )}

      <div className="buttons-div-objectives">
        <button
          className="next-button"
          id="previous"
          onClick={() => {
            feedQuesteeSliceData(0, "formIndex");
          }}
          disabled={applyingToHostQuest}
        >
          Previous
        </button>
        {!applyingToHostQuest && (
          <button className="next-button" onClick={() => updateQuest()}>
            Save
          </button>
        )}
        <button
          className="next-button"
          onClick={() => {
            updateQuest();
            formValidation();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
