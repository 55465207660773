import "./ProfileComponent.css";

import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import HelpComponent from "../../HelpComponent";
import Loader from "../../Loader";

export default function ProfileComponent(props) {
  const userData = useSelector((state) => state.authPersistReducer.userData);

  return (
    <div className="profile">
      <h5>Your profile</h5>

      <div className="profile-card">
        <Avatar
          src={`data:image/jpeg;base64,${userData.userPicture}`}
          sx={{ width: 50, height: 50, marginBottom: "20px" }}
          alt="Foto"
        />
        <span>
          {userData.name} {userData.lastName}
        </span>
        {props === "" || props === undefined ? (
          <div style={{ marginLeft: "0px" }}>
            <Loader type={"spinningBubbles"} color={"#ffffff"} />
          </div>
        ) : (
          <>
            <span>{userData.jobTitle}</span>
            <span>
              {userData.country} {userData.office}
            </span>
            <span>{userData?.Manager?.email}</span>
          </>
        )}
      </div>
      <HelpComponent />
    </div>
  );
}

ProfileComponent.propTypes = {
  jobTitle: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  managerEmail: PropTypes.string,
};
