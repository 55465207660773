import "./modalStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/redux/modalSlicer";

import React from "react";


const Modal = () => {
  const dispatch = useDispatch();
  const { modalState, displayItem, title } = useSelector(
    (state) => state.modalReducer
  );

  return (
    <div className="modal-holder" style={{ display: `${modalState.visible}` }}>
      <div className="modal-content-holder">
        <div className="modal-content">
          <div className="modal-title-holder">
            <p>{title}</p>
          </div>
          <div className="modal-text-holder">{displayItem}</div>
          <div className="modal-footer-holder">
            <button
              className="modal-dismiss-button"
              onClick={() => {
                dispatch(
                  closeModal({
                    title: "",
                    message: "",
                    displayItem: "",
                  })
                );
              }}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
