import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillDataDynamically } from "../../store/redux/questeeSlice.js";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QuestsAcordeon from "../../components/QuestsDashboard/QuestsAcordeon/index.jsx";
import Drawer from "@mui/material/Drawer";
import LineManagerTabs from "../LineManagerTabs/index.jsx";
import Avatar from "@mui/material/Avatar";
import "./LineManagerDrawer.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Api from "../../services/Api.jsx";
import NotePopup from "../NotePopup/index.jsx";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  toggleLoadingHandler,
  untoggleLoadingHandler,
} from "../../store/redux/loadingSlicer.js";

export default function LineManagerDrawer({ questInfo, title }) {
  const [state, setState] = React.useState(false);
  const [observation, setObservation] = React.useState("");
  const [approval, setApproval] = React.useState("");
  const [isClicked, setIsClicked] = React.useState(false);
  const userData = useSelector((state) => state.authPersistReducer.userData);

  const dispatch = useDispatch();

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  async function updateQuestStatus() {
    try {
      dispatch(toggleLoadingHandler());

      await Api.post(`/approval/updateStep/${questInfo.questId}`, {
        status: newStatus,
        approvalId: userData.userId,
        observation: observation,
        currentStep: questInfo.applicationType == "Questee" ? 2 : 4,
        isQuestee: questInfo.applicationType == "Questee" ? true : false,
        questeeId:
          questInfo.applicationType == "Questee" ? questInfo.questeeId : null,
      });
      dispatch(untoggleLoadingHandler());

      dispatch(
        fillDataDynamically({
          value: true,
          target: "updateQuestsForManager",
        })
      );
      setState(!state);
      window.location.href = "/#/linemanager/dashboard";
    } catch (err) {
      console.log(err);
    }
  }

  let newStatus = approval === true ? "approved" : "rejected";

  return (
    <div>
      <React.Fragment>
        <button className="approve-button-modal" onClick={toggleDrawer()}>
          {title} <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
        </button>
        <Drawer
          className="drawer-quests"
          variant="temporary"
          anchor="right"
          open={state}
          onClose={toggleDrawer()}
        >
          <div className="drawer-body">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1>
                {" "}
                <Avatar
                  alt=""
                  src={`data:image/jpeg;base64,${questInfo.picture}`}
                />
                {questInfo.lastName}, {questInfo.name}
              </h1>
              <IconButton
                color="primary"
                aria-label="close"
                onClick={() => setState(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="first-tags">
              <div className="tag">
                <h1>Type</h1>
                <div className="chip">{questInfo.type}</div>
              </div>
              <div className="tag">
                <h1>Host Location</h1>
                <h2>
                  <LocationOnIcon style={{ fontSize: "16px" }} />
                  {questInfo.hostLocation}
                </h2>
              </div>
              <div className="tag">
                <h1>Host</h1>
                <h2>{questInfo.host}</h2>
              </div>
            </div>
            <LineManagerTabs
              title1="Questee Information"
              title2={questInfo.type !=1?"Opportunity Information":undefined}
              element1={
                questInfo.questeeInformation ? (
                  <>
                    <div className="quest-objectives-modal">
                      <QuestsAcordeon
                        title={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_type_1"
                          ).answer
                        }
                        description={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_1"
                          ).answer
                        }
                      />
                      <QuestsAcordeon
                        title={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_type_2"
                          ).answer
                        }
                        description={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_2"
                          ).answer
                        }
                      />
                      <QuestsAcordeon
                        title={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_type_3"
                          ).answer
                        }
                        description={
                          questInfo.questeeInformation.find(
                            (q) => q.question == "objective_3"
                          ).answer
                        }
                      />
                    </div>
                    <div className="quest-values-modal">
                      <h2 className="quest-values-to-stakeholder">
                        Value to Stakeholders
                      </h2>

                      {questInfo.questeeInformation
                        .filter((q) => q.question.indexOf("value") > -1)
                        .map((q, index) => {
                          return (
                            <>
                              <span
                                className="modal-label"
                                style={{ fontSize: "13px" }}
                              >
                                {q.label}
                              </span>
                              <div className="quest-info-text">
                                <span>{q.answer}</span>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
              element2={
                questInfo.opportunityInformation ? (
                  <>
                    <div className="quest-objectives-modal">
                      <QuestsAcordeon
                        title={
                          questInfo.opportunityInformation.find(
                            (q) => q.question == "objective_type_1"
                          ).answer
                        }
                        description={
                          questInfo.opportunityInformation.find(
                            (q) => q.question == "objective_1"
                          ).answer
                        }
                      />
                    </div>
                    <div className="quest-values-modal">
                      <h2 className="quest-values-to-stakeholder">
                        Value to Stakeholders
                      </h2>

                      {questInfo.opportunityInformation
                        .filter((q) => q.question.indexOf("value") > -1)
                        .map((q, index) => {
                          return (
                            <>
                              <span
                                className="modal-label"
                                style={{ fontSize: "13px" }}
                              >
                                {q.label}
                              </span>
                              <div className="quest-info-text">
                                <span>{q.answer}</span>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
             
            />
            <div className="buttons-div-line-drawer">
              {/* <button
                className={
                  approval
                    ? "line-manager-approval-selected"
                    : "line-manager-approval"
                }
                disabled={questInfo.status !== "pending"}
                onClick={() => setApproval(true)}
              >
                Approve
              </button> */}

              <NotePopup
                  action2={() => {updateQuestStatus()}}
                  actionOnOpen = {() => setApproval(true)}
                  infoMessage="Are you sure you want to approve this Quest?"
                  option2="Submit"
                  classOption={
                    approval
                      ? "line-manager-approval-selected"
                      : "line-manager-approval"
                  }
                  title="Approve"
                  block={questInfo.status !== "pending"}
                />

              <button
                className={
                  approval && isClicked
                    ? "line-manager-reject"
                    : "line-manager-reject-selected"
                }
                onClick={() => {setApproval(false);setIsClicked(true)}}
                disabled={questInfo.status !== "pending"}
              >
                Reject
              </button>
            </div>

            {approval == false && isClicked ==true && (
              <div className="line-manager-comments">
                  <p className="line-manager-comment-title">
                  Please describe why the application has been rejected.
                  </p>
          
                <textarea
                  style={{ width: "100%" }}
                  disabled={questInfo.status !== "pending"}
                  defaultValue={
                    questInfo.status !== "pending"
                      ? "This feedback has already been submited."
                      : ""
                  }
                  onChange={(e) => setObservation(e.target.value)}
                  className="line-manager-comment-input"
                />
                <NotePopup
                  action2={() => updateQuestStatus()}
                  infoMessage="Are you sure you want to reject and provide feedback?"
                  option2="Submit feedback"
                  classOption="submit-button"
                  title="SUBMIT"
                />
              </div>
            )}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
