import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import "../PoliciesModal/PoliciesModal.css";

export default function AccordionComponent({ title, text }) {
  return (
    <div className="accordion-div">
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <AddCircleRoundedIcon style={{ color: "#E4610F" }} />
          <Typography style={{ marginLeft: "10px", marginTop: "1px" }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              textAlign: "justify",
              padding: "8px",
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            {text}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
