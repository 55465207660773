import { createSlice } from "@reduxjs/toolkit";

const initialState ={
  questId: 0,
  applyingToHostQuest: false,
  country: "",
  duplicatedCountry: false,
  flexibleDate: "",
  formIndex: 0,
  holidaysConflicts: "",
  holidayHasConflict: "",
  hostEmail: "",
  invalidForm: "",
  isReady: false,
  month: "",
  notFlexibleDate: "",
  notSelected: [],
  objective1: "",
  objective1Description: "",
  objective2: "",
  objective2Description: "",
  objective3: "",
  objective3Description: "",
  office: "",
  popUp: false,
  questApplication: false,
  questAsQuestee: [],
  questIdToApply: 0,
  questeesAplicants: [],
  selectedQuestees: [],
  selected: [],
  submited: false,
  type: [],
  updateQuestsForManager: false,
  user: "",
  value1: "",
  value2: "",
  value3: "",
  year: "",
  status: "",
  questStep: null
}

const questeeSlice = createSlice({
  name: "questeeSlice",
  initialState:initialState ,
  reducers: {
    fillQuesteeDataHandler: (state, action) => {
      state.questId = action.payload.questId;
      state.type = action.payload.type;
      state.notFlexibleDate = action.payload.notFlexibleDate;
      state.country = action.payload.country;
      state.office = action.payload.office;
      state.hostEmail = action.payload.hostEmail;
      state.month = action.payload.month;
      state.year = action.payload.year;
      state.flexibleDate = action.payload.flexibleDate;
      state.holidaysConflicts = action.payload.holidaysConflicts;
      state.holidayHasConflict = action.payload.holidayHasConflict;
      state.objective1 = action.payload.objective1;
      state.objective1Description = action.payload.objective1Description;
      state.objective2 = action.payload.objective2;
      state.objective2Description = action.payload.objective2Description;
      state.objective3 = action.payload.objective3;
      state.objective3Description = action.payload.objective3Description;
      state.value1 = action.payload.value1;
      state.value2 = action.payload.value2;
      state.value3 = action.payload.value3;
      state.status = action.payload.status;
      state.questStep = action.payload.questStep;
      if (action.payload.submited) {
        state.submited = action.payload.submited;
      }

    },
    fillDataDynamically: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
    reduxApplyToQuest: (state, action) => {
      state.applyingToHostQuest = action.payload.applyingToHostQuest;
      state.country = action.payload.country;
      state.formIndex = action.payload.formIndex;
      state.hostEmail = action.payload.hostEmail;
      state.office = action.payload.office;
      state.questApplication = action.payload.questApplication;
      state.questIdToApply = action.payload.questIdToApply;
      state.type = action.payload.type;
      state.status = action.payload.status;
      state.questStep = action.payload.questStep;
    },
    addSelectedQuestee: (state, action) => {
      state.selected = [...state.selected, action.payload];
    },
    addNotSelectedQuestee: (state, action) => {
      state.notSelected = action.payload;
    },
    removeNotSelected: (state, action) => {
      state.notSelected = action.payload;
    },
    resetQuesteeData: () => initialState,
  },
});

export const fillQuesteeDataHandler =
  questeeSlice.actions.fillQuesteeDataHandler;
export const fillDataDynamically = questeeSlice.actions.fillDataDynamically;
export const reduxApplyToQuest = questeeSlice.actions.reduxApplyToQuest;
export const addSelectedQuestee = questeeSlice.actions.addSelectedQuestee;
export const removeNotSelected = questeeSlice.actions.removeNotSelected;
export const addNotSelectedQuestee = questeeSlice.actions.addNotSelectedQuestee;
export const resetQuesteeData = questeeSlice.actions.resetQuesteeData;


export default questeeSlice.reducer;
