import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../../pages/HostApprovalPage/HostApprovalPage.css';
import './NotePopup.css';

export default function NotePopup({title, classOption, titleMessage, infoMessage, option1, option2, action2, block, actionOnOpen, style}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if(actionOnOpen !== undefined){
      actionOnOpen()
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action2Functions = () => {
    try {
      action2();
      setOpen(false);
    } catch(err){
    }
  }


  return (
    <div>
      <button disabled={block} onClick={handleClickOpen} className={classOption} style={style}>
        {title}      
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='popup-title'>
          {titleMessage}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='popupu-message'>
           {infoMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <button onClick={action1Functions} className='pop-option1'>{option1}</button> */}
          <button onClick={action2Functions} autoFocus className='pop-option2'>
            {option2}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
