import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../services/Api.jsx";
import { useMsal, useAccount } from "@azure/msal-react";
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-common";
import { loginRequest } from "../../authConfig.js";
import { loginUser } from "../../services/Authentication/userAuthentication.js";
import {
  isApplicationHandler,
  msTokenHandler,
  setAuthHandler,
  signIn,
  storeUserData,
} from "../../store/redux/authSlice.js";
import { fillQuesteeDataHandler, resetQuesteeData } from "../../store/redux/questeeSlice.js";
import { fillQuestDataDinamically } from "../../store/redux/questSlice.js";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const userData = useSelector((state) => state?.authPersistReducer?.userData);

  useEffect(() => {
    dispatch(
      setAuthHandler({
        auth: true,
      })
    );
  }, []);

  const fillQuesteeData = (userData) => {
    if (userData.Quests.length) {
      dispatch(
        fillQuesteeDataHandler({
          questId: userData.Quests[0].questId,
          type: userData.Quests[0].type,
          notFlexibleDate: userData.Quests[0].reasonForDate,
          country: userData.Quests[0].country,
          office: userData.Quests[0].office,
          hostEmail: userData.host?.User?.email,
          month: userData.Quests[0].month,
          year: userData.Quests[0].year,
          flexibleDate: userData.Quests[0].dateFlexible,
          holidaysConflicts: userData.Quests[0].holidaysConflict,
          holidayHasConflict: userData.Quests[0].reasonForHoliday,
          objective1: userData.questions[0].answer,
          objective1Description: userData.questions[1].answer,
          objective2: userData.questions[2].answer,
          objective2Description: userData.questions[3].answer,
          objective3: userData.questions[4].answer,
          objective3Description: userData.questions[5].answer,
          value1: userData.questions[6].answer,
          value2: userData.questions[7].answer,
          value3: userData.questions[8].answer,
          submited: userData.Quests[0].currentStep > 1,
          status: userData.Quests[0].QuesteeInfos[0].status,
          questStep: userData.Quests[0].QuesteeInfos[0].questStep,
        })
      );
    }else{
      dispatch(resetQuesteeData());
    }
  };

  useEffect(() => {
    if (account) {
      const tokenRequest = {
        account: account,
        scopes: ["User.Read"],
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          return handleAuthorization(response);
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.loginPopup(loginRequest).then(async (response) => {
              return handleAuthorization(response);
            });
          }
          throw e;
        });
    }
  }, [account,instance]);

  async function handleAuthorization(response) {
    dispatch(
      msTokenHandler({
        msToken: response.accessToken,
      })
    );
    try {
      const AuthStatus = await Api.post("user/authenticate", null, {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      });
      if (AuthStatus.data.status === true) {
        dispatch(
          signIn({
            userRole: AuthStatus.data.user.Roles.map((role) => role.roleId),
            name: response.account.name.split(",")[1],
            msToken: response.accessToken,
            token: AuthStatus.data.token,
            isEligible: AuthStatus.data.isEligible,
            questeeStatus: AuthStatus.data.user.questeeStatus,
          })
        );
        loginUser(AuthStatus.data.token, AuthStatus.data.user);
        dispatch(storeUserData({ data: AuthStatus.data.user }));
        if (userData && userData.Quests && userData.Quests.length > 0) {
          fillQuesteeData(AuthStatus.data.user);
        }else{
          resetQuesteeData();
        }
      }
    } catch (error) {
      console.log(error);
      return;
    }
  }

  useEffect(() => {
    Api.get("/cycle/findOpenCycle").then((response) => {
      let traditionalQuestOpen = response.data.find(
        (cycle) => cycle.cycleType === 1
      )
        ? true
        : false;
      let gbaQuestOpen = response.data.find((cycle) => cycle.cycleType === 2)
        ? true
        : false;
      let hostQuestOpen = response.data.find((cycle) => cycle.cycleType === 3)
        ? true
        : false;
      let traditionalQuestId = traditionalQuestOpen
        ? response.data.find((cycle) => cycle.cycleType === 1).cycleId
        : 0;
      let gbaQuestId = gbaQuestOpen
        ? response.data.find((cycle) => cycle.cycleType === 2).cycleId
        : 0;
      let hostQuestId = hostQuestOpen
        ? response.data.find((cycle) => cycle.cycleType === 3).cycleId
        : 0;
      dispatch(
        fillQuestDataDinamically({
          traditionalQuestOpen,
          gbaQuestOpen,
          hostQuestOpen,
          traditionalQuestId,
          gbaQuestId,
          hostQuestId,
        })
      );
    });
  }, [dispatch]);

  return <div>{children}</div>;
};

export default Layout;
