import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import LineManagerQuesteeInfoModal from "../../Dashboard/LineManager/LineManagerQuesteeInfoModal";
import Moment from "react-moment";
import LineManagerDrawer from "../../LineManagerDrawer";

export default function   QuesteeTableRow({ type, questeeData }) {
  const [questInformation, setQuestInformation] = useState(
  {
    "userId": "",
    "name": "",
    "lastName": "",
    "email": "",
    "applicationType": "",
    "hostLocation": "",
    "status": "",
    "questId": null
  }
  
  )
  ;
  useEffect(() => {

    return setQuestInformation(  {
      "userId": questeeData.userId,
      "name": questeeData.name,
      "lastName": questeeData.lastName,
      "email": questeeData.email,
      "applicationType": questeeData.applicationType,
      "hostLocation": questeeData.hostLocation,
      "status": questeeData.status,
      "questId": questeeData.questId
    }
    );

  
  }, [questeeData]);
   ;
  function Status({ status }) {
    if (status === "pending") {
      return <div className="analyzing-tag">ANALYZING</div>;
    }
    if (status === "approved") {
      return <div className="approved-tag">APPROVED</div>;
    }
    if (status === "rejected") {
      return <div className="rejected-tag">REJECTED</div>;
    }
  }
  return (
    <TableRow
      key={questInformation.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell className="orange-table-cell" component="th" scope="row">
        {questeeData.name + " "}
        {questeeData.lastName}
      </TableCell>
      <TableCell className="orange-table-cell" align="left">
      {questeeData.applicationType}
      </TableCell>
      <TableCell align="left">
        {questeeData.country}
        </TableCell>
      <TableCell align="left">
        {questeeData.date}
        </TableCell>
      <TableCell align="right">
        <Status status={questeeData.status} />
      </TableCell>
      <TableCell>
        {questeeData.status === "pending" && (
          <LineManagerDrawer questInfo={questeeData} title='View Application'/>
         )}  
      </TableCell>
    </TableRow>
  );
}
