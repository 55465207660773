import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/authSlice";
import questeeReducer from "./redux/questeeSlice";
import userReducer from "./redux/userSlice";
import hostReducer from "./redux/hostSlice";
import modalReducer from "./redux/modalSlicer";
import loadReducer from "./redux/loadingSlicer";
import questReducer from "./redux/questSlice";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  version: 2,
  migrate: (persistedState, currentVersion) => {
    if (persistedState && persistedState._persist.version !== currentVersion) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(persistedState);
  },
};


const authPersistReducer = persistReducer(persistConfig, authSlice);


const questeePersistConfig = {
  key: "quest",
  storage,
  version: 1, 
  migrate: (persistedState, currentVersion) => {
    if (persistedState && persistedState._persist.version !== currentVersion) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(persistedState);
  },
};

const questeePersistReducer = persistReducer(questeePersistConfig, questeeReducer);


const questPersistConfig = {
  key: "questConfig",
  storage,
  version: 2, 
  migrate: (persistedState, currentVersion) => {
    if (persistedState && persistedState._persist.version !== currentVersion) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(persistedState);
  },
};

const questPersistReducer = persistReducer(questPersistConfig, questReducer);

export const store = configureStore({
  reducer: {
    authPersistReducer,
    questeePersistReducer,
    questPersistReducer,
    userReducer,
    hostReducer,
    modalReducer,
    loadReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
